import { Component, OnInit, ViewChild } from '@angular/core';
import {StatusService} from '../../../services/status.service';
import {DataSellImm} from '../../../data/data-sell-imm';
import {DbService} from '../../../services/db.service';
import { HttpHeaders,HttpClient} from '@angular/common/http';
import {AppSettings} from '../../../app-settings';
import {Router} from '@angular/router';

import { FlashMessagesService } from 'angular2-flash-messages';
import {Resp} from '../../../models/resp';
import {RespSavedDocument} from '../../../models/resp-saved-document';


@Component({
  selector: 'app-sell-imm',
  templateUrl: './sell-imm.component.html',
  styleUrls: ['./sell-imm.component.css'],
  providers: [DbService]
})



export class SellImmComponent implements OnInit {

    /* roba spostata da document-icon-sell-imm */

    public statusPrintSellImm:boolean;
    public statusViewSellImm:boolean;

    private statusMsg:string;


    private ads;
    private bene;
    private obj;
    public showWhere:string;

    private dataSellImm=new DataSellImm();

	@ViewChild('sellimmForm',{static:false}) form;

  @ViewChild('opt',{static:false}) option;

	private disableForm=false;
	private hideSuccessMessage=true;





 checkAccess(){
    if(!this.statusService.getAccess()){
        this.router.navigate(['']);
    }
  }

  ngOnInit() {
    this.checkAccess();
  	this.dataSellImm=this.statusService.getSellImm();

      const idB=this.statusService.getIdBeneficiario();
    if(idB==undefined){
      window.alert('seleziona un beneficiario prima di iniziare a produrre un documento');
      this.router.navigate(['/dashboard']);
    }


  }

  updateMessage(){

    this._flashMessagesService.show(this.statusMsg, { cssClass: 'alert-success', timeout: 3700 });
    console.warn("updateMessage()");
  }
  showUpdateButton(){
    return this.statusService.updateDoc('SELLIMM');
  }

  saveData(){
      this.statusService.setNewDoc('SELLIMM');
  	console.log(this.form.valid);
  	if(this.form.valid){
  		this.disableForm=true;
  		this.hideSuccessMessage=false;
  		this.statusService.updateSellImm(this.dataSellImm);
      this.statusService.setPrintSellImm(true);
      this.statusPrintSellImm=this.statusService.getSellImm().print;
      this.statusMsg="campi impostati correttamente";
      this.updateMessage();

  	}
  }

   enableForm(){
       this.hideSuccessMessage=true;
       this.disableForm=false;
       this.statusMsg="documento pronto per essere modificato";

       this.updateMessage();



    }

   activateForm(){
       this.hideSuccessMessage=true;
       this.disableForm=false;

     }

    clear(){
        this.statusService.updateSellImm(new DataSellImm());
        this.statusService.setPrintSellImm(false);
        this.statusService.setViewSellImm(false);
        this.statusPrintSellImm=this.statusService.getSellImm().print;
        this.statusViewSellImm=this.statusService.getSellImm().view;
        this.dataSellImm=this.statusService.getSellImm();
        this.activateForm();
        this.statusMsg="nuovo documento generato con successo";
        this.updateMessage();

    }

  updateData(){
      if(this.form.valid){
        this.disableForm=true;
        this.hideSuccessMessage=false;
        this.statusService.updateSellImm(this.dataSellImm);
        this.statusService.setPrintSellImm(true);
        this.statusPrintSellImm=this.statusService.getSellImm().print;
        this.statusMsg="campi aggiornati correttamente";
        this.updateMessage();

      }
  }


   constructor(private router:Router,private _flashMessagesService: FlashMessagesService,public statusService:StatusService,public dbService:DbService, public http:HttpClient) {


    this.statusPrintSellImm=statusService.getSellImm().print;
      console.log("statusPrint: ",this.statusPrintSellImm);
      this.statusViewSellImm=statusService.getSellImm().view;
      console.log("statusView:",this.statusViewSellImm);
  }

  getStatusMessage(){
        return this.statusMsg;
   }



  setAds(a){

    //2
    this.ads=a;
    console.log(this.ads);
    console.warn(`#WARN da sell-imm stampo idBeneficiario da statusSerice: ${this.statusService.getIdBeneficiario()}`);

      this.dbService.getBeneficiario(this.statusService.getIdBeneficiario())

      .subscribe(data => this.setBene((data as Resp).data));
  }

  setBene(b){
    //3
    this.bene=b;
    console.log(this.bene);
    //faccio chiamata a libreria per creare pdf

    const data = {ads:JSON.stringify(this.ads),bene:JSON.stringify(this.bene),obj:JSON.stringify(this.obj), id:this.statusService.getId()};

    const json=JSON.stringify(data);

      /*var params=json;
    let urlSearchParams = new URLSearchParams();
        urlSearchParams.append('ads', );
        urlSearchParams.append('bene', this.bene.json());
        urlSearchParams.append('fields', this.obj.json());
        let params = urlSearchParams.toString();*/

        console.log(json);
        const headers=new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
       return this.http.post(`${AppSettings.API_ENDPOINT}/php/documents/${AppSettings.TRIBUNAL_ENDPOINT}/sellimm.php`,json,{headers:headers})

         .subscribe(dataRes => this.showDocument(dataRes));
  }

  showDocument(data){

    this.showWhere=data.fileName;
    this.statusService.setViewSellImm(true);
    this.statusViewSellImm=this.statusService.getSellImm().view;
    this.statusMsg="documento generato con successo";
    this.updateMessage();



  }

  print(){

    //1


     this.obj=this.statusService.getSellImm();
    this.dbService.getAmministratore(this.statusService.getId())

      .subscribe( data => this.setAds((data as Resp).data));




    console.log("print() called()");
    console.log(this.obj);


  }



  save(){
    console.log("ho chiamato il metodo save: ");
  this.obj=this.statusService.getSellImm();

  console.log(this.obj);

   const update= this.statusService.updateDoc('SELLIMM');
    let data;
    let json;



   if(update){
      const idDoc=this.statusService.getIdDocToSave();
      data = {obj:JSON.stringify(this.obj), id:this.statusService.getId(),idDoc:idDoc,kind:"SELLIMM",idBeneficiario:this.statusService.getIdBeneficiario()};
      json=JSON.stringify(data);
      console.warn('this.dbService.update2db(json) loggo json:');
      console.log(json);
     this.dbService.update2db(json)

       .subscribe(dataRes => this.showSuccessMsg(dataRes));

    }else
    {
      data = {obj:JSON.stringify(this.obj), id:this.statusService.getId(),kind:"SELLIMM",idBeneficiario:this.statusService.getIdBeneficiario()};
      console.log("sono su ramo save (non update), salverò: ",data);
      json=JSON.stringify(data);
      console.log("da salvare ");
      console.log(json);
       this.dbService.save2db(json)

         .subscribe(dataRes =>{
           if((dataRes as RespSavedDocument).id!=-1){
            this.statusService.updateIdDocToSave((dataRes as RespSavedDocument).id);
          }

         this.showSuccessMsg(dataRes);
       });


    }


  }

  showSuccessMsg(msg){
    console.log(msg);
    this.statusMsg="documento salvato con successo";
    this.updateMessage();

  }


/* fine roba spostata */

}

