import { Injectable } from '@angular/core';





@Injectable()

export class AppSettings {

    static tribunalName = '';

    public static set TRIBUNAL_ENDPOINT(name){
        this.tribunalName = name;
    }

    public static get TRIBUNAL_ENDPOINT(): string {
        return this.tribunalName.toLowerCase();
    }

    public static get API_ENDPOINT(): string {

     /*
     //let mode = 'prod';
    let mode = 'dev';  // correct one (set this before pushing to origin)
	 	else if(mode=='dev')
			return  '';
		else
			//return '/gest';
			return '';
    */
    return '';
  }

}
