import { Component, OnInit,ViewChild, Input,EventEmitter,Output } from '@angular/core';
import {LangService} from '../../services/lang.service';
import {Ricorrente,Relazione} from '../../models/ricorrente';
import {DbService} from '../../services/db.service';
import {Comune,Indirizzo,IndirizzoStrutturato} from '../../models/indirizzo';

export class updateAuto{
  autoricorso:boolean;
  ricorrente:Ricorrente;
}





@Component({
  selector: 'input-ricorrente',
  templateUrl: './ricorrente.component.html',
  styleUrls: ['./ricorrente.component.css'],
   providers: [LangService,DbService]
})
export class RicorrenteComponent implements OnInit {

private changed:boolean;


   public parenteOptions = [
        { value: 'figlio-a', label: 'Figlio/a' },
        { value: 'fratello-sorella', label: 'Fratello / Sorella' },
        { value: 'altro_entro_4_grado', label: 'Altro parente entro il IV grado' }
    ];

    public affineOptions = [
        { value: 'coniuge', label: 'Coniuge' },
        { value: 'convivente_stabile', label: 'Convivente stabile' },
        { value: 'suocero_a', label: 'Suocero/a' },
        { value: 'cognato_a', label: 'Cognato/a' },
        { value: 'genero_nuora', label: 'Genero/Nuora' }
    ];


  public comune:Comune;
  public indirizzo:Indirizzo;

 constructor(private langService:LangService, private dbService:DbService) { }

 @ViewChild('ricorrenteForm',{static:false}) form;
 @Input() n:number;
 @Input() ricorrente: Ricorrente;
 @Input() req:boolean;
 @Output() autoricorsoEmitter:EventEmitter<updateAuto> =new EventEmitter<updateAuto>();
 @Input() idTribunale:number;


 copyAddress(){

   if(this.ricorrente.indirizzoStrutturato!=undefined){
     console.log('this.ricorrente.indirizzoStrutturato!=undefined');

     if(this.ricorrente.indirizzoStrutturato.indirizzo.prefixTopo!='SCONOSCIUTO'){
       if(this.ricorrente.indirizzoStrutturato.comune.provincia!='' && this.ricorrente.indirizzoStrutturato.comune.provincia!=null)
        this.ricorrente.indirizzo=`${this.ricorrente.indirizzoStrutturato.indirizzo.prefixTopo} ${this.ricorrente.indirizzoStrutturato.indirizzo.denomTopo} ${this.ricorrente.indirizzoStrutturato.indirizzo.civico!=''?'n.':''} ${this.ricorrente.indirizzoStrutturato.indirizzo.civico}${this.ricorrente.indirizzoStrutturato.comune.cap!=''?',':''} ${this.ricorrente.indirizzoStrutturato.comune.cap} ${this.ricorrente.indirizzoStrutturato.comune.comune} (${this.ricorrente.indirizzoStrutturato.comune.provincia})`;
       else
          this.ricorrente.indirizzo=`${this.ricorrente.indirizzoStrutturato.indirizzo.prefixTopo} ${this.ricorrente.indirizzoStrutturato.indirizzo.denomTopo} ${this.ricorrente.indirizzoStrutturato.indirizzo.civico!=''?'n.':''} ${this.ricorrente.indirizzoStrutturato.indirizzo.civico}${this.ricorrente.indirizzoStrutturato.comune.cap!=''?',':''} ${this.ricorrente.indirizzoStrutturato.comune.cap} ${this.ricorrente.indirizzoStrutturato.comune.comune}`;

     } else {
       this.ricorrente.indirizzo='SCONOSCIUTO';
     }


   } else {
     console.log('this.ricorrente.indirizzoStrutturato==undefined');


     if(this.indirizzo.prefixTopo!='SCONOSCIUTO'){

      if(this.comune.provincia!='' && this.comune.provincia!=null)


      this.ricorrente.indirizzo=`${this.indirizzo.prefixTopo} ${this.indirizzo.denomTopo} ${this.indirizzo.civico!=''?'n.':''} ${this.indirizzo.civico}${this.comune.cap!=''?',':''} ${this.comune.cap} ${this.comune.comune} (${this.comune.provincia})`;
       else
         this.ricorrente.indirizzo=`${this.indirizzo.prefixTopo} ${this.indirizzo.denomTopo} ${this.indirizzo.civico!=''?'n.':''} ${this.indirizzo.civico}${this.comune.cap!=''?',':''} ${this.comune.cap} ${this.comune.comune}`;
     } else {
       this.ricorrente.indirizzo='SCONOSCIUTO';
     }

      this.ricorrente['indirizzoStrutturato']={indirizzo:{prefixTopo:this.indirizzo.prefixTopo,denomTopo:this.indirizzo.denomTopo,civico:this.indirizzo.civico,nazionalita:this.indirizzo.nazionalita},comune:{cap:this.comune.cap,comune:this.comune.comune,provincia:this.comune.provincia}};

   }

    console.log('copyAddress()')
    console.log( this.ricorrente.indirizzoStrutturato);

 }

 setComuneScelto(comune:Comune){
   console.log('alla setComune arriva:')
   console.log(comune);
   if(this.ricorrente.indirizzoStrutturato!=undefined){

       this.ricorrente.indirizzoStrutturato.comune.cap=comune.cap;
       this.ricorrente.indirizzoStrutturato.comune.comune=comune.comune;
       this.ricorrente.indirizzoStrutturato.comune.provincia=comune.provincia;
   } else {
      this.comune.cap=comune.cap;
      this.comune.comune=comune.comune;
      this.comune.provincia=comune.provincia;
   }

   console.log(`cap ${comune.cap} comune ${comune.comune} prov ${comune.provincia}`);
   this.copyAddress();
   }

 setIndirizzo(indirizzo:Indirizzo){
   console.log('alla setINdirizzo arriva')
   console.log(indirizzo);

    if(this.ricorrente.indirizzoStrutturato!=undefined){

       this.ricorrente.indirizzoStrutturato.indirizzo.prefixTopo=indirizzo.prefixTopo;
     this.ricorrente.indirizzoStrutturato.indirizzo.denomTopo=indirizzo.denomTopo;
     this.ricorrente.indirizzoStrutturato.indirizzo.civico=indirizzo.civico;
     this.ricorrente.indirizzoStrutturato.indirizzo.nazionalita=indirizzo.nazionalita;
    } else
    {
       this.indirizzo.prefixTopo=indirizzo.prefixTopo;
   this.indirizzo.denomTopo=indirizzo.denomTopo;
   this.indirizzo.civico=indirizzo.civico;
   this.indirizzo.nazionalita=indirizzo.nazionalita;

    }

   this.copyAddress();


 }

changeQualificaCandidato(){

  if(this.ricorrente.candidato=='candidato'){

     if(this.ricorrente.qualificaCandidato=='coniuge'){
      //parente
        this.ricorrente.relazione=0;

    }
    if(this.ricorrente.qualificaCandidato=='convivente'){
      //altro
        this.ricorrente.relazione=2;

    }
    if(this.ricorrente.qualificaCandidato=='parente'){
      //parente
        this.ricorrente.relazione=0;
    }
    if(this.ricorrente.qualificaCandidato=='designato'){
      //altro
        this.ricorrente.relazione=2;
    }
    if(this.ricorrente.qualificaCandidato=='estraneo'){
      //altro
        this.ricorrente.relazione=2;
    }

  }



}

getComuneForRicorrente(){
  if(this.ricorrente.indirizzoStrutturato==undefined || this.ricorrente.indirizzoStrutturato== null)
    return {cap:'',comune:'',provincia:''};
  else return this.ricorrente.indirizzoStrutturato.comune;
}
getIndirizzoForRicorrente(){
   if(this.ricorrente.indirizzoStrutturato==undefined || this.ricorrente.indirizzoStrutturato == null)
    return {prefixTopo:'',denomTopo:'',civico:'',nazionalita:''};
  else return this.ricorrente.indirizzoStrutturato.indirizzo;
}


getRelazione(){
  return this.ricorrente.relazione;
}

  //#input (input)="input.value=$event.target.value.toUpperCase()"


  ngOnInit() {

    if(this.ricorrente.indirizzoStrutturato!=undefined){

        this.comune={cap:'',comune:'',provincia:''};
        this.indirizzo={prefixTopo:'',denomTopo:'',civico:'',nazionalita:''};


    this.indirizzo.prefixTopo=this.ricorrente.indirizzoStrutturato.indirizzo.prefixTopo;
    this.indirizzo.denomTopo=this.ricorrente.indirizzoStrutturato.indirizzo.denomTopo;
    this.indirizzo.civico=this.ricorrente.indirizzoStrutturato.indirizzo.civico;
    this.comune.cap=this.ricorrente.indirizzoStrutturato.comune.cap;
    this.comune.comune=this.ricorrente.indirizzoStrutturato.comune.comune;
    this.comune.provincia=this.ricorrente.indirizzoStrutturato.comune.provincia;
    this.indirizzo.nazionalita=this.ricorrente.indirizzoStrutturato.indirizzo.nazionalita;
   } else {
     this.comune={cap:'',comune:'',provincia:''};
    this.indirizzo={prefixTopo:'',denomTopo:'',civico:'',nazionalita:''};


   }


     this.changed=false;
     if(this.ricorrente.candidato=='autoricorso')
       this.changed=true;
  }


  LabelRelazione(){
    return this.langService.relazione();
  }

  isValid(){
    return this.form.valid;
  }

  LabelProfessione(){
    return this.langService.professione();
  }

 // ricorrente=new Ricorrente();
  LabelNome(){
  	return this.langService.nome();
  }
  LabelGradoDiParentela(){
  	return this.langService.gradoDiParentela(this.getRelazione());
  }
  PlaceholderGradoDiParentela(){
    return this.langService.placeholderGradoDiParentela(this.getRelazione());
  }

 LabelCognome(){
 		return this.langService.cognome();
 }

 LabelDataDiNascita(){
 	return this.langService.dataDiNascita();
 }

LabelLuogoDiNascita(){
	return this.langService.luogoDiNascita();
}

LabelCodiceFiscale(){
	return this.langService.codiceFiscale();
}

LabelIndirizzo(){
	return this.langService.indirizzo();
}

PlaceholderIndirizzo(){
  return this.langService.indirizzoPlaceholder();
}

LabelTelefono(){
  	return this.langService.telefono();
  }

LabelCellulare(){
  	return this.langService.cellulare();
  }

LabelEmail(){
  	return this.langService.email();
  }
 LabelCandidato(){
 		return this.langService.candidato();
 }


 fireAutoricorso(){
   if(this.ricorrente.candidato=='autoricorso'){
     this.changed=true;
     this.autoricorsoEmitter.emit({"autoricorso":true,"ricorrente":this.ricorrente});
   }
   if(this.changed){
     if(this.ricorrente.candidato!='autoricorso'){
        this.changed=false;
        this.autoricorsoEmitter.emit({"autoricorso":false,"ricorrente":this.ricorrente});
     }
   }

 }

    updateGrado() {
        if (this.idTribunale === 2) {
            const options = this.getGradoOptions();
            if (options.length > 0) {
                this.ricorrente.grado = options[0].value; // Set to first available option
            } else {
                this.ricorrente.grado = ''; // Clear if no options available
            }
        } else {
            this.ricorrente.grado = ''; // Clear for text input
        }
    }

    getGradoOptions() {
        if (this.ricorrente.relazione === 0) {
            return this.parenteOptions;
        } else if (this.ricorrente.relazione === 1) {
            return this.affineOptions;
        }
        return [];
    }



    onRelationChange(value: number) {
        this.ricorrente.relazione = value;
        this.updateGrado();
    }


}
