import { Injectable } from '@angular/core';

import {Relazione} from '../models/ricorrente';

@Injectable()
export class LangService {

  constructor() { }

  LabelRg(){
      return "Rg";
  }

    LabelCartaIdNumero(){
      return "Numero carta identità";
    }

    LabelCartaIdRilasciato(){
      return "Documento rilasciato da";
    }
    LabelCartaIdRilasciatoData(){
      return "Data emissione documento";
    }
    LabelDelegatoNome(){
      return 'Nome delegato';
    }

    LabelPec(){
      return 'PEC';
    }

    LabelDelegatoCognome(){
      return 'Cognome delegato';
    }

  LabelSearch(){
    return "inserisci cap nome comune";
  }

    LabelAnnoRendiconto(){
      return "anno rendiconto";
    }
    LabelAltro(){
      return "altro";
    }

  CapComune(){
    return "Cap o Comune";
  }


  nuovoDocumento(){
    return "crea nuovo documento";
  }


  creaDocumento(){
    return "crea documento";
  }

  aggiornaDocumento(){
    return "aggiorna documento corrente";
  }

  backToLogIn(){
    return "torna al login";
  }

  backToMain(){
    return "torna al menù";
  }

  telefono(){
    return "telefono fisso";
  }

  professione(){
    return "professione";
  }

  cellulare(){
    return "cellulare";
  }

  gradoDiParentela(rel:Relazione){
    if(rel==Relazione.Parente)
      return "grado di parentela";
    else if(rel==Relazione.Affine)
      return "grado di affinità";
  }

    placeholderGradoDiParentela(rel:Relazione){
    if(rel==Relazione.Parente)
      return "es: madre";
    else if(rel==Relazione.Affine)
      return "es: marito della sorella";
  }

  relazione(){
    return "releazione parente/affine"
  }

  inizioTutela(){
    return "data inizio amm.d.s.";
  }
   send(){

  	return "invia";
  }

  numero(){
    return "numero nomina";
  }
  operatore(){
    return "operatore";
  }

  tribunale(){
    return "tribunale";
  }
  window(){
    return "sportello";
  }

  anagrafica(){
    return "anagrafica";
  }

  updateSuccessMsg(){
    return "informazioni aggiornate con successo";
  }

  nome(){
  	return "nome";
  }

  cognome(){
  	return "cognome";

  }

  dataDiNascita(){
  	return "data di nascita";
  }

  luogoDiNascita(){
  	return "luogo di nascita";
  }

  labelKind(){
    return "ruolo (volontario, familiare, etc...)";
  }

indirizzo(){
	return "indirizzo completo";
}

indirizzoPlaceholder(){
  return "via/piazza,etc ..., cap, comune, provincia";
}

sesso() {
  return "sesso";
}

codiceFiscale(){
	return "codice fiscale";
}

  passwordMismatch(){
  	return "le due password non corrispondono";
  }

  emailMismatch(){
  	return "i due indirizzi email non corrispondono";
  }

  email(){
  	return "email";
  }
  candidato(){
    return "candidato";
  }

  ripetiEmail(){
  	return "ripeti email";
  }

  password(){
  	return "password";
  }
  ripetiPassword(){
  	return "ripeti password";
  }


}
