import { Component, Input, Output, EventEmitter  } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {AppSettings} from '../../app-settings';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent {

    @Input() fileName = '';
    @Output() loadingStateChange = new EventEmitter<boolean>();


    pdfUrl: SafeResourceUrl | null = null;

    private fileURL = null;

    constructor(
        private http: HttpClient,
        private sanitizer: DomSanitizer
    ) {}

    openPdf(){
        window.open(this.fileURL);
    }

    cleanPdf(elementId){


        URL.revokeObjectURL(this.fileURL);
        const containerElement = document.getElementById(elementId);
        if (!containerElement) {
            console.error(`Elemento con ID "${elementId}" non trovato.`);
            this.loadingStateChange.emit(false);
            return;
        }

        const button = containerElement.getElementsByTagName('button')[0];
        button.remove();
    }

    getPdf(fileName,elementId) {
        this.fileName = fileName;
        if (!this.fileName) {
            console.error('Nome file non specificato');
            return;
        }
        this.loadingStateChange.emit(true); // Inizio caricamento
         const fileUrl = `${AppSettings.API_ENDPOINT}/fileService/serveProtectedFile?file=${this.fileName}`;


        this.http.get(fileUrl, {
            responseType: 'blob',
            observe: 'response'
        }).subscribe(
            (response) => {
                const contentDisposition = response.headers.get('Content-Disposition');
                let extractedFileName = this.fileName; // Default file name

                // Estrai il nome del file se l'header 'Content-Disposition' è presente
                if (contentDisposition) {
                    const matches = /filename="([^"]+)"/.exec(contentDisposition);
                    if (matches != null && matches[1]) {
                        extractedFileName = matches[1];
                    }
                }

                // Cerca l'elemento specificato dall'ID
                const containerElement = document.getElementById(elementId);
                if (!containerElement) {
                    console.error(`Elemento con ID "${elementId}" non trovato.`);
                    this.loadingStateChange.emit(false);
                    return;
                }

                const file = new Blob([response.body], { type: 'application/pdf' });
                this.fileURL = URL.createObjectURL(file);
                //saveAs(file, extractedFileName); // Salva il file con il nome corretto
                const anchor = document.createElement('a');
                anchor.href = this.fileURL;
                anchor.download = extractedFileName; // Specifica il nome del file qui
                anchor.text ="scarica documento";
                anchor.addEventListener('click', () => {
                    setTimeout(() => {
                        // Rimuovi l'elemento <a> e pulisci la memoria
                        //button.remove();
                        //URL.revokeObjectURL(this.fileURL);
                    }, 3000); // Rimuovi l'elemento dopo il click
                });

                // Crea un elemento <button> e configura il suo stile
                const button = document.createElement('button');
                button.style.cursor = 'pointer'; // Opzionale: cambia il cursore per il bottone

                // Crea un elemento <i> per l'icona
                const icon = document.createElement('i');
                icon.className = 'fa fa-arrow-down';
                icon.setAttribute('aria-hidden', 'true');

                // Aggiungi l'icona all'elemento <a>
                anchor.appendChild(icon);

                // Aggiungi l'elemento <a> al bottone
                button.appendChild(anchor);


                containerElement.appendChild(button);

                this.loadingStateChange.emit(false);

            },
            error => {
                console.error('Errore nel caricamento del PDF:', error);
                // Gestisci l'errore (ad esempio, mostra un messaggio all'utente)
            }
        );
    }
}
