import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

@Component({
  selector: 'richiesta-copie',
  templateUrl: './richiesta-copie.component.html',
  styleUrls: ['./richiesta-copie.component.css']
})
export class RichiestaCopieComponent implements OnInit {



@Input() copie;
@Input() idTribunale=1;

@Input() ricorrenti;
@Output() sendCopie = new EventEmitter();
@Output() disableSendCopie= new EventEmitter();

  constructor() { }


@ViewChild('richiestaCopieForm',{static:false}) form;


  disableForm(){
    console.warn("form modificato");
    this.disableSendCopie.emit();
  }

  valuechange(val){
     this.disableSendCopie.emit();
  }

  sendForm(){

  	if(this.form.valid){
  		this.sendCopie.emit();
  	}


  }

  loggami(event){
    setTimeout(()=>{
      console.log(`il bool vale ${this.copie.nominaUrgenteBool}`);
      console.log(`evento vale ${event}`)
    },400)
  }



  ngOnInit() {
  }

}
