
 import {Component, OnInit, ViewChild} from '@angular/core';
import { trigger,
    state,
    style,
    transition,
    animate,
    keyframes } from '@angular/animations';
import {StatusService} from '../../services/status.service';
import {DbService} from '../../services/db.service';
import {Router} from '@angular/router';
import { BsModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';
import { Amministratore } from '../../models/amministratore';
import { Beneficiario} from '../../models/beneficiario';
import {AppSettings} from '../../app-settings';
import {HttpHeaders,HttpClient} from '@angular/common/http';
import {Resp} from '../../models/resp';
 import {DataRichiestaCopie} from '../../data/data-richiesta-copie';
 import {formatDate} from '@angular/common';

@Component({
  selector: 'app-document-archive',
  templateUrl: './document-archive.component.html',
  styleUrls: ['./document-archive.component.css'],
  providers:[DbService],
  animations:[
      trigger('movePanel', [

            transition('* => *', [
                animate("4s", keyframes([
                    style({opacity: 0, transform: 'rotate(45deg)',offset: 0 }),
                    style({opacity: 0.3, transform: 'rotate(45deg)',offset: 0.15 }),
                    style({opacity: 0.7, transform: 'rotate(45deg)',offset: 0.30 }),
                    style({opacity: 1, transform: 'rotate(45deg)',offset: 0.45 }),
                    style({opacity: 1, transform: 'rotate(45deg)',offset: 0.60 }),
                    style({opacity: 1, transform: 'rotate(45deg)',offset: 0.75 }),
                    style({opacity: 1, transform: 'rotate(45deg)',offset: 0.90 }),
                    style({opacity: 1, transform: 'rotate(45deg)',offset: 1 })
                ]))
            ])

        ])
  ]


})

export class DocumentArchiveComponent implements OnInit {


/* metodi modale */

    @ViewChild('richiestaCopieModal',{static:false})
    richiestaCopieModal: BsModalComponent;

    @ViewChild('notaIscrizioneModal',{static:false})
    notaIscrizioneModal: BsModalComponent;



    selected: string;
    output: string;




    nomeRichiestaCopie:string;
    nomeNotaIscrizione:string;
    backdropOptions = [true, false, 'static'];
    cssClass: string = '';

    animation: boolean = true;
    keyboard: boolean = true;
    backdrop: string | boolean = true;
    css: boolean = false;

    selectedAds:Amministratore;
    selectedBene:Beneficiario;
    selectedKind:string;

    linkRichiestaCopie:boolean;
    pathRichiestaCopie:boolean;
    linkNotaIscrizione:boolean;
    pathNotaIscrizione:boolean;




   closedRC() {
      this.linkRichiestaCopie=false;
    }

    closedNI() {
      this.linkRichiestaCopie=false;
    }

    dismissedRC() {
        this.linkNotaIscrizione=false;
    }
    dismissedNI() {
        this.linkNotaIscrizione=false;
    }

    openedRC() {

        //this.linkRichiestaCopie=false;
    }
    openedNI() {
       // this.linkNotaIscrizione=false;
    }


    openRichiestaCopie(kind,id) {
        this.loadProfile(id,kind,'copy',this);
        this.selectedKind=kind;
        this.richiestaCopieModal.open();
       // this.print('copy',kind);
    }
    openNotaIscrizione(kind,id) {
        this.loadProfile(id,kind,'note',this);
        this.selectedKind=kind;
        this.notaIscrizioneModal.open();
       // this.print('note',kind);
    }



   /* salvaModale(){
       this.delete(this.id);
       this.modal.close();
    }*/







  /*fine metodi modale */


  /* INIZIO MODALE PER CANCELLAZIONE */


/* metodi modale */

  @ViewChild('deleteModal',{static:false})
    deleteModal: BsModalComponent;

    selectedDocument: string;



    idToDelete:number;
    number:number;


   closed() {

    }

    dismissed() {

    }

    opened() {

    }


    open() {
        this.deleteModal.open();
    }

    setModalDelete(numero:number,idDocumento:number){
      this.idToDelete=idDocumento;
      this.number=numero;
      this.deleteModal.open();
    }

    salvaModale(){
       this.deleteDoc(this.idToDelete);
       this.deleteModal.close();
    }







  /*fine metodi modale */

  /* FINE MODALE PER CANCELLAZIONE */




/* generazione richiesta copie e nota iscrizione a ruolo */

obj;
ads;
bene;

  print(kind,idTipoDocumento:string){

    //1



    this.dbService.getAmministratore(this.statusService.getId())
      //.map(res => res.json())
      .subscribe( data => this.setAds((data as Resp).data,kind,idTipoDocumento));




    console.log("print() called() :=> ",kind);



  }

  setAds(a,kind,idTipoDocumento){

    //2
    this.ads=a;
    console.log(this.ads);

      this.dbService.getBeneficiario(this.statusService.getIdBeneficiario())
     // .map(res => res.json())
      .subscribe(data => this.setBene((data as Resp).data,kind,idTipoDocumento));
  }

  setBene(b,kind,idTipoDocumento:string){
    //3
    this.bene=b;
    console.log(this.bene);
    //faccio chiamata a libreria per creare pdf

    var data = {"ads":JSON.stringify(this.ads),"bene":JSON.stringify(this.bene),"idTipoDocumento":idTipoDocumento, "id":this.statusService.getId()};

    var json=JSON.stringify(data);


        var headers;
        switch(kind){
          case 'copy':
          console.log(json);
               headers=new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });
              this.http.post(`${AppSettings.API_ENDPOINT}/php/documents/${AppSettings.TRIBUNAL_ENDPOINT}/copy.php`,json,{headers:headers})
               //.map(res => res.json())
               .subscribe(data => this.showDocumentCopy(data));

          break;
          case 'note':{
             console.log(json);
               headers=new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });
              this.http.post(`${AppSettings.API_ENDPOINT}/php/documents/${AppSettings.TRIBUNAL_ENDPOINT}/note.php`,json,{headers:headers})
               //.map(res => res.json())
               .subscribe(data => this.showDocumentNote(data));

          break;
          }

        }


  }

  showDocumentCopy(data){

    this.pathRichiestaCopie=data.fileName;
    this.linkRichiestaCopie=data.linkRichiestaCopie;

   console.log("richiesta copie creata");


  }
  getCopyCompleted(){
    return this.linkRichiestaCopie;
  }

    getNoteCompleted(){
    return this.linkNotaIscrizione;
  }

  showDocumentNote(data){

    this.pathNotaIscrizione=data.fileName;
    this.linkNotaIscrizione=true;


  }




/* fine generazioe documenti */



  constructor(private http:HttpClient,private router:Router, private statusService:StatusService, private dbService:DbService) { }
  private list;
  private account;

  private idSportello;


  checkAccess(){
    if(!this.statusService.getAccess()){
        this.router.navigate(['']);
    }

  }


  ngOnInit() {
    this.checkAccess();



    this.list=[];
     this.account=this.statusService.getAccount();

   // if(this.account.kind=='ads')
    this.idSportello=this.statusService.getIdSportello();
    if(this.idSportello!=0){

      this.dbService.loadWindowDocumentList(this.idSportello)
      //.map(res=>res.json())
      .subscribe(data =>this.logList(this.list=(data as Resp).data));

    }

    if(this.idSportello==0){
    	this.dbService.loadDocumentList(this.statusService.getId())
    	//.map(res=>res.json())
    	.subscribe(data =>this.logList(this.list=(data as Resp).data));
     }
   /* else
       this.dbService.loadOperatorDocumentList(this.statusService.getSportello())
      .map(res=>res.json())
      .subscribe(data =>this.logList(this.list=data.data));*/



  }

  isOperator(){
    return this.idSportello!=0;
  }

  logList(list){
    console.log(list);

  }


  loadDoc(tipoDocumento:string, idDocumento:number,idAccount:number){

    console.log("loadDoc chiamata");

    this.idSportello=this.statusService.getIdSportello();
    console.log("id sportello vale: ",this.idSportello);

    if(this.idSportello!=0){
      console.log("id sportello !=0 quindi  sono un operatore");
      this.dbService.getAccountFromId(idAccount)
       /*.map(res =>{
           if(res.status==204)
             throw new Error("Utente/email inesistente");

           if(res.status==406)
              throw new Error("errore di rete");

           if(res.status==200)
             return res.json();



         })*/
              .subscribe(
              data =>  this.configDoc((data as Resp).data,idDocumento,tipoDocumento),
              err => console.error(err+"errore da login standard")
              );
    }else
    {
       this.dbService.getDocFromDb(idDocumento,tipoDocumento)
    //.map(res => res.json())
    .subscribe(data => {

        console.log('getDocFromDb di riga 388 data vale');
        console.log(data);

      this.configLoadedDoc(tipoDocumento,(data as Resp).data.content,idDocumento,(data as Resp).data.idBeneficiario);
          }
      //console.log(data));


    )}







  }
  configDoc(account,idDocumento,tipoDocumento){
    console.log('inizio configDoc, account vale')
    console.log(account);
    this.statusService.setLoadedAccount(account);
    this.statusService.updateAccount(account);

     this.dbService.getAmministratore(this.statusService.getId())
      //.map(res => res.json())
      .subscribe( data => {

        console.log('sono la config doc ho ricevuto dal db, caricando l\'amministratore, ')
        console.log(data);
        this.statusService.updateSurname((data as any).data.cognome);

         this.statusService.updateAmministratore((data as Resp).data);

      });

    alert("profilo caricato con successo!");


      this.dbService.getDocFromDb(idDocumento,tipoDocumento)
   // .map(res => res.json())
    .subscribe(data => {

      console.log('getDocFromDb di riga 424 data vale');
      console.log(data);
      this.configLoadedDoc(tipoDocumento,(data as Resp).data.content,idDocumento,(data as Resp).data.idBeneficiario);

    }

      );


      //console.log(data));

  }




  deleteDoc(idDocumento:number){
    console.log("deleteDoc called, idDoc:: ",idDocumento);
    this.dbService.deleteRequest(idDocumento)
    //.map(res => res.json())
    .subscribe(data => this.setDeleteRequest(data,idDocumento));

  }


  setDeleteRequest(data,idDocumento){
     console.log("set deleteDoc called");
    if(data.status=='Success'){
       console.log("success");

       //aggiorno lista documenti

        this.account=this.statusService.getAccount();

   // if(this.account.kind=='ads')
    this.idSportello=this.statusService.getIdSportello();
    if(this.idSportello!=0){

      this.dbService.loadWindowDocumentList(this.idSportello)
      //.map(res=>res.json())
      .subscribe(data =>this.logList(this.list=(data as Resp).data));

    }

    if(this.idSportello==0){
      this.dbService.loadDocumentList(this.statusService.getId())
      //.map(res=>res.json())
      .subscribe(data =>this.logList(this.list=(data as Resp).data));
     }


      /*for (var i = 0; i < this.list.length; i++) {
            if (this.list[i].idDocumento === idDocumento) {
                this.list[i].requestDelete = true;
                break;
            }
        }*/

    }
  }


  showDeleteIcon(idDoc){
     for (var i = 0; i < this.list.length; i++) {
            if (this.list[i].idDocumento === idDoc) {
                return !this.list[i].requestDelete;

            }
        }

  }




  showKindDoc(kind){
    switch(kind){

        case 'SELLIMM':
          return 'richiesta di autoriz. vendita immobile';
        case 'BUYIMM':
          return 'Richiesta di autoriz. acquisto beni immobili';
        case 'LEGACY':
          return 'richiesta di accettazione eredità';
        case 'SELLGOODS':
          return 'richiesta di autoriz. vendita beni';
        case 'BUYCAR':
          return 'richiesta autoriz. acquisto autovettura';
        case 'EXT':
          return 'richiesta mod. decreto di estensione nomina';
        case 'AUTH':
          return 'richiesta autorizzazione generica';

      }
  }


  configLoadedDoc(tipo,content,idDocumento,idBeneficiario){

    console.log(`configLoadedDoc, idBeneficiario vale ${idBeneficiario}`)



  	  this.statusService.setLoadedDoc(idDocumento,tipo);
      this.statusService.activeBeneficiarioFromId(idBeneficiario);

  	  switch(tipo){

  	  	case 'SELLIMM':
          this.statusService.updateSellImm(content);
          this.statusService.setViewSellImm(false);
  	  		this.router.navigate(['/sellImm']);
  	  		break;
        case 'BUYIMM':
         this.statusService.updateBuyImm(content);
         this.statusService.setViewBuyImm(false);
          this.router.navigate(['/buyImm']);
          break;
        case 'SELLGOODS':
         this.statusService.updateSellGoods(content);
           this.statusService.setViewSellGoods(false);
          this.router.navigate(['/sellGoods']);
          break;
        case 'AUTH':
         this.statusService.updateAuth(content);
           this.statusService.setViewAuth(false);
          this.router.navigate(['/auth']);
          break;
        case 'LEGACY':
         this.statusService.updateLegacy(content);
           this.statusService.setViewLegacy(false);
          this.router.navigate(['/legacy']);
          break;
        case 'BUYCAR':
         this.statusService.updateBuyCar(content);
           this.statusService.setViewBuyCar(false);
          this.router.navigate(['/buyCar']);
          break;
        case 'EXT':
         this.statusService.updateExt(content);
          this.statusService.setViewExt(false);
          this.router.navigate(['/ext']);
          break;
  	  }


  }


  loadProfile(idAccount:number, kind, text,generaDoc){

    console.log("loadProfile chiamata");

    this.idSportello=this.statusService.getIdSportello();
    console.log("id sportello vale: ",this.idSportello);

    if(this.idSportello!=0){
      console.log("id sportello !=0 quindi  sono un operatore");
      this.dbService.getAccountFromId(idAccount)
       /*.map(res =>{
           if(res.status==204)
             throw new Error("Utente/email inesistente");

           if(res.status==406)
              throw new Error("errore di rete");

           if(res.status==200)
             return res.json();



         })*/
              .subscribe(
              data =>  this.configDocForProfile((data as Resp).data,kind,text ,generaDoc),
              err => console.error(err+"errore da login standard")
              );
    }
  }


  configDocForProfile(account,kind,text, generaDoc){
    this.statusService.setLoadedAccount(account);
     this.dbService.getAmministratore(this.statusService.getId())
      //.map(res => res.json())
      .subscribe( data =>  this.statusService.updateSurname((data as Resp).data.cognome),generaDoc.print(text,kind));




  }

}
