import {Injectable} from '@angular/core';
import {Ricorrente} from '../models/ricorrente';
import {Beneficiario} from '../models/beneficiario';
import {Ricorso} from '../models/ricorso';
import {formatDate} from '@angular/common';



// Nuovo tipo per i dettagli specifici di Udine
export interface DetailsUdine {
    elementiAzioni: any[];
    bisogniMensili: any[];
    situazioneAlloggiativa: any[];
    beniBeneficiario: any[];
    allegatiRicorso: any[];
}

@Injectable()
export class DataRicorso {


	public ricorrenti:Ricorrente[];
	public beneficiario:Beneficiario;
	public details:Ricorso;
	public print:boolean;
	public view:boolean;
	public nrg:string;
	public goToRelatives:boolean;
	public relativesCompleted:boolean;
    public data:string;
    public detailsUdine?: DetailsUdine; // Campo opzionale per i dettagli di Udine

	//new Ricorrente("alberto","tozzi","10/02/1950","HGHYUY89G89G888D","Palermo","viale Alberato n° 15 44750 PA","maschio","434343","343434343","asd@a.it","candidato","cugino")
	//new Beneficiario(0,"alberto","tozzi","10/02/1950","HGHYUY89G89G888D","Palermo","viale Alberato n° 15 44750 PA","maschio","cugino")


    constructor(
        ric: Ricorrente[] = [new Ricorrente(), new Ricorrente(), new Ricorrente(), new Ricorrente(), new Ricorrente(), new Ricorrente()],
        benef: Beneficiario = new Beneficiario(),
        det: Ricorso = new Ricorso(),
        print: boolean = false,
        view: boolean = false,
        nrg: string = '',
        goToRelatives: boolean = false,
        relativesCompleted: boolean = false,
        data: string = formatDate(new Date().toDateString(), 'dd/MM/yyyy', 'it-IT'),
        detailsUdine?: DetailsUdine
    ) {
        this.ricorrenti = ric;
        this.beneficiario = benef;
        this.details = det;
        this.print = print;
        this.view = view;
        this.nrg = nrg;
        this.goToRelatives = goToRelatives;
        this.relativesCompleted = relativesCompleted;
        this.data = data;
        this.detailsUdine = detailsUdine;
    }
}
