// input-azioni-beneficiario.component.ts

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {UdineInitModelService} from '../../services/udine-init-model.service';

export enum Opzione {
    Autonomo = 1,
    ConAssistenza = 2,
    Sostituito = 3
}

export interface ElementoLista {
    id: number;
    descrizione: string;
    scelta: Opzione;
    inputFields?: {
        limite?: string;
        limiteBancario?: string;
        altro?: string;
    };
    inputChoice?: 'settimanali' | 'mensili';
}

@Component({
    selector: 'input-azioni-beneficiario',
    templateUrl: './input-azioni-beneficiario.component.html',
    styleUrls: ['./input-azioni-beneficiario.component.scss']
})
export class InputAzioniBeneficiarioComponent implements OnInit {
    @Input() elementi: ElementoLista[] = [];
    @Output() elementiChange = new EventEmitter<ElementoLista[]>();

    public Opzione = Opzione;

    constructor(private udineInitModelService:UdineInitModelService) { }

    ngOnInit(): void {
        if (this.elementi.length === 0) {
            this.elementi = this.getDefaultElementi();
            this.elementiChange.emit(this.elementi);
        }
    }

    public onSceltaChange(elemento: ElementoLista, nuovaScelta: Opzione): void {
        elemento.scelta = nuovaScelta;
        this.elementiChange.emit(this.elementi);
    }

    private getDefaultElementi(): ElementoLista[] {
        return this.udineInitModelService.getDefaultElementi();
    }
}
