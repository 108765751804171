import {Injectable} from '@angular/core';

@Injectable()

export class DataRichiestaCopie {
      public procedimento: string;
      public numeroCopie: number;
      public mode: string;  // urgenza / noUrgenza
      public quality: string; // autentiche / semplici
      public kind: string; // PROVVEDIMENTO DEL GIUDICE TUTELARE
      // DECRETO NOMINA  AMM. DI SOSTEGNO
      // DECRETO FISSAZIONE UDIENZA
      // VERBALE DI GIURAMENTO
      public idx: number;
      public nominaUrgenteMotivazioni: string;
      public nominaUrgenteBool: boolean;
      public date: string;
      public comuneSportello: string;
      public ud_numeroIdPagamentoTelematico:string;
      public ud_telefono:string;
      public ud_email:string;
      public ud_pec:string;


      constructor(numero: string= '', nCopie: number= 1, urg: string= 'noUrgenza', auth: string= 'autentiche', k: string= '', i: number= 0, motivazioniUrgenza: string= '', nominaUrgente: boolean= false, date: string = '', comuneSportello = '',numeroIdPagamentoTelematico:string = '', telefono:string = '',email:string = '', pec:string = '') {
          this.procedimento = numero;
          this.numeroCopie = nCopie;
          this.mode = urg;
          this.quality = auth;
          this.kind = k;
          this.idx = i;
          this.nominaUrgenteMotivazioni = motivazioniUrgenza;
          this.nominaUrgenteBool = nominaUrgente;
          this.date = date;
          this.comuneSportello = comuneSportello;
          this.ud_numeroIdPagamentoTelematico = numeroIdPagamentoTelematico;
          this.ud_telefono = telefono;
          this.ud_email = email;
          this.ud_pec = pec;
	    }
}
