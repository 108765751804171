// input-bisogni-mensili.component.ts

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {UdineInitModelService} from '../../services/udine-init-model.service';

export interface BisognoMensile {
    id: number;
    descrizione: string;
    importo: string;
    inputFields?: {
        altro?: string;
    };
}

@Component({
    selector: 'input-bisogni-mensili',
    templateUrl: './input-bisogni-mensili.component.html',
    styleUrls: ['./input-bisogni-mensili.component.scss']
})
export class InputBisogniMensiliComponent implements OnInit {
    @Input() bisogni: BisognoMensile[] = [];
    @Output() bisogniChange = new EventEmitter<BisognoMensile[]>();

    constructor(private udineInitModelService:UdineInitModelService) { }

    ngOnInit(): void {
        if (this.bisogni.length === 0) {
            this.bisogni = this.getDefaultBisogni();
            this.bisogniChange.emit(this.bisogni);
        }
    }

    onImportoChange(bisogno: BisognoMensile, nuovoImporto: string): void {
        bisogno.importo = nuovoImporto;
        this.bisogniChange.emit(this.bisogni);
    }

    private getDefaultBisogni(): BisognoMensile[] {
        return this.udineInitModelService.getDefaultBisogni();
    }
}
