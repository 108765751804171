import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders,HttpResponse } from '@angular/common/http';
import {DbService} from '../../services/db.service';
import { BsModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {map,timeout} from 'rxjs/operators';
import {Observable,interval} from 'rxjs';
import {LangService} from '../../services/lang.service';
import {Ricorrente} from '../../models/ricorrente';
import {AppSettings} from '../../app-settings';



//import {DataSellImm} from '../../data-sell-imm';


import {StatusService} from '../../services/status.service';
import {NavigationEnd} from '@angular/router';
import {Ricorso } from '../../models/ricorso';
import {Beneficiario} from '../../models/beneficiario';
import {DataParenti} from '../../data/data-parenti';
import {DataSportello} from '../../data/data-sportello';
import { FlashMessagesService } from 'angular2-flash-messages';

import {Resp} from '../../models/resp';

@Component({
  selector: 'app-elenco-parenti',
  templateUrl: './elenco-parenti.component.html',
  styleUrls: ['./elenco-parenti.component.css'],
  providers: [LangService,DbService]
})
export class ElencoParentiComponent implements OnInit {

	 public statusPrintRicorso:boolean;
  public statusViewRicorso:boolean;
   private dataRicorso: DataParenti;

 constructor(private router:Router,private _flashMessagesService: FlashMessagesService,public statusService:StatusService,private route:ActivatedRoute, private http:HttpClient,private dbService:DbService, private langService: LangService) {

      this.dataRicorso=new DataParenti();


  }

  private _idRicorso:string;
  private obj;
  private _sportello:DataSportello;

  private disableForm:boolean;
  private hideSuccessMessage:boolean;
  private hideCreateAccuontMessage:boolean;

  public statusMsg:string;
  private disableCreateAccountForm:boolean;
  private createAccountMessage:string;
   private disableRefresh:boolean;
     public showWhere:string;

     private benef:Beneficiario;


     public ricorsoDraft:boolean;

    @ViewChild('etichetteModal',{static:false})
    transizioneModal: BsModalComponent;


    //ric1;ric2;ric3;ric4;ric5;ric6;
    @ViewChild('ric1',{static:false}) ric1;
    @ViewChild('ric2',{static:false}) ric2;
    @ViewChild('ric3',{static:false}) ric3;
    @ViewChild('ric4',{static:false}) ric4;
    @ViewChild('ric5',{static:false}) ric5;
    @ViewChild('ric6',{static:false}) ric6;
    @ViewChild('ric7',{static:false}) ric7;
    @ViewChild('ric8',{static:false}) ric8;
    @ViewChild('ric9',{static:false}) ric9;
    @ViewChild('ric10',{static:false}) ric10;
    @ViewChild('ric11',{static:false}) ric11;
    @ViewChild('ric12',{static:false}) ric12;
    @ViewChild('ric13',{static:false}) ric13;
    @ViewChild('ric14',{static:false}) ric14;
    @ViewChild('ric15',{static:false}) ric15;
    @ViewChild('ric16',{static:false}) ric16;
    @ViewChild('ric17',{static:false}) ric17;
    @ViewChild('ric18',{static:false}) ric18;
    @ViewChild('ric19',{static:false}) ric19;
    @ViewChild('ric20',{static:false}) ric20;

    @ViewChild('ricorsoForm',{static:false}) ricForm;

  closedTransizione(){}
  dismissedTransizione(){

  }



  openedTransizione(){

  }










  ngOnInit() {
      this.ricorsoDraft=this.statusService.getRicorso().details.draft;
      if(this.ricorsoDraft)
        this.hideSuccessMessage=true;
      console.log(`sono in modalità bozza? ${this.ricorsoDraft}`);
      this.statusService.setNewDoc('PARENTI');
  	  this._idRicorso=this.route.snapshot.params['idRicorso'];
      this.statusService.setPrintParenti(false);
      this.statusService.setViewParenti(false);


      this.statusPrintRicorso=this.statusService.getParenti().print;
      console.log("statusPrint: ",this.statusPrintRicorso);
      this.statusViewRicorso=this.statusService.getParenti().view;
      console.log("statusView:",this.statusViewRicorso);

  	  console.log(this._idRicorso);
  	  this.disableCreateAccountForm=false;
      this.createAccountMessage="ricorso creato con successo!";
      this.hideCreateAccuontMessage=true;
      this.disableForm=false;
     // this.hideSuccessMessage;
      console.log(this.statusService.getSpecialAccount());




      this.dbService.getBeneFromRicorsoFromDb(+this._idRicorso)
      	//.map(res =>res.json())
        .subscribe(data => this.setBene((data as Resp).data.beneficiario));



  }

  setBene(b){
    console.warn("setBene()");
  	 this.statusService.updateBeneficiarioInRicorso(b);
     console.log("dopoUpdateBeneficiarioInRicorso(b)");

     this.dbService.getParentiFromDb(+this._idRicorso)
      //.map(res => res.json())
      .subscribe(data => this.setElencoParenti((data as Resp).data));

//

  }


  setElencoParenti(data){
    console.warn("setElencoParenti()");
    console.log("parenti caricati da db:");
    console.log(data);
    if(data.parenti!=false){
      console.log("ho caricato i parenti dal db e data.parenti vale");
      console.log(data.parenti);
      this.statusService.updateElencoParentiInRicorso(data.parenti);

      this.statusService.updateDetailsInRicorso(data.details);

        const _currentPar=this.statusService.getParenti();
        console.log('_currentPar vale');
        console.log(_currentPar);

        if(_currentPar.ricorrenti.length<=10){

          const len=_currentPar.ricorrenti.length;

          for(let i=len;i<20;i++){
            _currentPar.ricorrenti.push(new Ricorrente());
          }



        console.log('elenco parenti reinizializzato a 20 slot');
        this.statusService.updateElencoParentiInRicorso(_currentPar.ricorrenti);
        console.log(_currentPar.ricorrenti);
      }





    }else
      console.log("non ho caricato i parenti dal db");

      this.statusService.setLoadedDoc(+this._idRicorso,"PARENTI");
      this.dataRicorso=this.statusService.getParenti();





  }


   backToLogIn(){
      return this.langService.backToLogIn();
    }


    creaDocumento(){
      return this.langService.creaDocumento();
    }

    createRicorso(){
    	console.log("bottone cliccato");
    	console.log("riccorrenti: ",this.dataRicorso.details.nRicorrenti);

      let ricorrentiValid=false;

    	switch(this.dataRicorso.details.nRicorrenti.toString()){


          case '0':{
              console.log("nessun ricorrente");
              ricorrentiValid=true;

          break;
        }

    		case '1':{
    			console.log("un ricorrente");
    			console.log(this.ric1.ricorrente);
          this.ric1.form.onSubmit();
         // this.ric1.disableFormFielsd();
          ricorrentiValid=this.ric1.isValid();
    		break;
      }
    		case '2':{
    			 console.log("due ricorrenti");
    			 console.log(this.ric1.ricorrente);
    			 console.log(this.ric2.ricorrente);
            this.ric1.form.onSubmit();
            this.ric2.form.onSubmit();
            ricorrentiValid=this.ric1.isValid() && this.ric2.isValid();
    		break;
      }
    		case '3':{
            this.ric1.form.onSubmit();
            this.ric2.form.onSubmit();
            this.ric3.form.onSubmit();
            ricorrentiValid=this.ric1.isValid() && this.ric2.isValid() && this.ric3.isValid();

    		break;
      }
    		case '4': {
            this.ric1.form.onSubmit();
            this.ric2.form.onSubmit();
            this.ric3.form.onSubmit();
            this.ric4.form.onSubmit();
            ricorrentiValid=this.ric1.isValid() && this.ric2.isValid() && this.ric3.isValid() && this.ric4.isValid();

    		break;
      }
    		case '5': {
            this.ric1.form.onSubmit();
            this.ric2.form.onSubmit();
            this.ric3.form.onSubmit();
            this.ric4.form.onSubmit();
            this.ric5.form.onSubmit();
            ricorrentiValid=this.ric1.isValid() && this.ric2.isValid() && this.ric3.isValid() && this.ric4.isValid() && this.ric5.isValid();


    		break;
      }
    		case '6': {

            this.ric1.form.onSubmit();
            this.ric2.form.onSubmit();
            this.ric3.form.onSubmit();
            this.ric4.form.onSubmit();
            this.ric5.form.onSubmit();
            this.ric6.form.onSubmit();
            ricorrentiValid=this.ric1.isValid() && this.ric2.isValid() && this.ric3.isValid() && this.ric4.isValid() && this.ric5.isValid() && this.ric6.isValid();



    		break;
      }
    		case '7': {

            this.ric1.form.onSubmit();
            this.ric2.form.onSubmit();
            this.ric3.form.onSubmit();
            this.ric4.form.onSubmit();
            this.ric5.form.onSubmit();
            this.ric6.form.onSubmit();
            this.ric7.form.onSubmit();
            ricorrentiValid=this.ric1.isValid() && this.ric2.isValid() && this.ric3.isValid() && this.ric4.isValid() && this.ric5.isValid() && this.ric6.isValid() && this.ric7.isValid();



    		break;
      }


      		case '8': {

            this.ric1.form.onSubmit();
            this.ric2.form.onSubmit();
            this.ric3.form.onSubmit();
            this.ric4.form.onSubmit();
            this.ric5.form.onSubmit();
            this.ric6.form.onSubmit();
            this.ric7.form.onSubmit();
            this.ric8.form.onSubmit();
            ricorrentiValid=this.ric1.isValid() && this.ric2.isValid() && this.ric3.isValid() && this.ric4.isValid() && this.ric5.isValid() && this.ric6.isValid() && this.ric7.isValid() && this.ric8.isValid();



    		break;
      }

      case '9': {

            this.ric1.form.onSubmit();
            this.ric2.form.onSubmit();
            this.ric3.form.onSubmit();
            this.ric4.form.onSubmit();
            this.ric5.form.onSubmit();
            this.ric6.form.onSubmit();
            this.ric7.form.onSubmit();
            this.ric8.form.onSubmit();
            this.ric9.form.onSubmit();
            ricorrentiValid=this.ric1.isValid() && this.ric2.isValid() && this.ric3.isValid() && this.ric4.isValid() && this.ric5.isValid() && this.ric6.isValid() && this.ric7.isValid() && this.ric8.isValid() && this.ric9.isValid();



    		break;
      }
      case '10': {

            this.ric1.form.onSubmit();
            this.ric2.form.onSubmit();
            this.ric3.form.onSubmit();
            this.ric4.form.onSubmit();
            this.ric5.form.onSubmit();
            this.ric6.form.onSubmit();
            this.ric7.form.onSubmit();
            this.ric8.form.onSubmit();
            this.ric9.form.onSubmit();
            this.ric10.form.onSubmit();
            ricorrentiValid=this.ric1.isValid() && this.ric2.isValid() && this.ric3.isValid() && this.ric4.isValid() && this.ric5.isValid() && this.ric6.isValid() && this.ric7.isValid() && this.ric8.isValid() && this.ric9.isValid() && this.ric10.isValid() ;



    		break;
      }
       case '11': {

            this.ric1.form.onSubmit();
            this.ric2.form.onSubmit();
            this.ric3.form.onSubmit();
            this.ric4.form.onSubmit();
            this.ric5.form.onSubmit();
            this.ric6.form.onSubmit();
            this.ric7.form.onSubmit();
            this.ric8.form.onSubmit();
            this.ric9.form.onSubmit();
            this.ric10.form.onSubmit();
            ricorrentiValid=this.ric1.isValid() && this.ric2.isValid() && this.ric3.isValid() && this.ric4.isValid() && this.ric5.isValid() && this.ric6.isValid() && this.ric7.isValid() && this.ric8.isValid() && this.ric9.isValid() && this.ric10.isValid()  && this.ric11.isValid() ;



        break;
      }
       case '12': {

            this.ric1.form.onSubmit();
            this.ric2.form.onSubmit();
            this.ric3.form.onSubmit();
            this.ric4.form.onSubmit();
            this.ric5.form.onSubmit();
            this.ric6.form.onSubmit();
            this.ric7.form.onSubmit();
            this.ric8.form.onSubmit();
            this.ric9.form.onSubmit();
            this.ric10.form.onSubmit();
            ricorrentiValid=this.ric1.isValid() && this.ric2.isValid() && this.ric3.isValid() && this.ric4.isValid() && this.ric5.isValid() && this.ric6.isValid() && this.ric7.isValid() && this.ric8.isValid() && this.ric9.isValid() && this.ric10.isValid() && this.ric11.isValid()  && this.ric12.isValid() ;



        break;
      }
       case '13': {

            this.ric1.form.onSubmit();
            this.ric2.form.onSubmit();
            this.ric3.form.onSubmit();
            this.ric4.form.onSubmit();
            this.ric5.form.onSubmit();
            this.ric6.form.onSubmit();
            this.ric7.form.onSubmit();
            this.ric8.form.onSubmit();
            this.ric9.form.onSubmit();
            this.ric10.form.onSubmit();
            ricorrentiValid=this.ric1.isValid() && this.ric2.isValid() && this.ric3.isValid() && this.ric4.isValid() && this.ric5.isValid() && this.ric6.isValid() && this.ric7.isValid() && this.ric8.isValid() && this.ric9.isValid() && this.ric10.isValid() && this.ric11.isValid()  && this.ric12.isValid() && this.ric13.isValid() ;



        break;
      }
 case '14': {

            this.ric1.form.onSubmit();
            this.ric2.form.onSubmit();
            this.ric3.form.onSubmit();
            this.ric4.form.onSubmit();
            this.ric5.form.onSubmit();
            this.ric6.form.onSubmit();
            this.ric7.form.onSubmit();
            this.ric8.form.onSubmit();
            this.ric9.form.onSubmit();
            this.ric10.form.onSubmit();
            ricorrentiValid=this.ric1.isValid() && this.ric2.isValid() && this.ric3.isValid() && this.ric4.isValid() && this.ric5.isValid() && this.ric6.isValid() && this.ric7.isValid() && this.ric8.isValid() && this.ric9.isValid() && this.ric10.isValid() && this.ric11.isValid()  && this.ric12.isValid() && this.ric13.isValid() && this.ric14.isValid() ;



        break;
      }
 case '15': {

            this.ric1.form.onSubmit();
            this.ric2.form.onSubmit();
            this.ric3.form.onSubmit();
            this.ric4.form.onSubmit();
            this.ric5.form.onSubmit();
            this.ric6.form.onSubmit();
            this.ric7.form.onSubmit();
            this.ric8.form.onSubmit();
            this.ric9.form.onSubmit();
            this.ric10.form.onSubmit();
            ricorrentiValid=this.ric1.isValid() && this.ric2.isValid() && this.ric3.isValid() && this.ric4.isValid() && this.ric5.isValid() && this.ric6.isValid() && this.ric7.isValid() && this.ric8.isValid() && this.ric9.isValid() && this.ric10.isValid() && this.ric11.isValid()  && this.ric12.isValid() && this.ric13.isValid() && this.ric14.isValid() && this.ric15.isValid() ;



        break;
      }
 case '16': {

            this.ric1.form.onSubmit();
            this.ric2.form.onSubmit();
            this.ric3.form.onSubmit();
            this.ric4.form.onSubmit();
            this.ric5.form.onSubmit();
            this.ric6.form.onSubmit();
            this.ric7.form.onSubmit();
            this.ric8.form.onSubmit();
            this.ric9.form.onSubmit();
            this.ric10.form.onSubmit();
            ricorrentiValid=this.ric1.isValid() && this.ric2.isValid() && this.ric3.isValid() && this.ric4.isValid() && this.ric5.isValid() && this.ric6.isValid() && this.ric7.isValid() && this.ric8.isValid() && this.ric9.isValid() && this.ric10.isValid() && this.ric11.isValid()  && this.ric12.isValid() && this.ric13.isValid() && this.ric14.isValid() && this.ric15.isValid() && this.ric16.isValid();



        break;
      }
 case '17': {

            this.ric1.form.onSubmit();
            this.ric2.form.onSubmit();
            this.ric3.form.onSubmit();
            this.ric4.form.onSubmit();
            this.ric5.form.onSubmit();
            this.ric6.form.onSubmit();
            this.ric7.form.onSubmit();
            this.ric8.form.onSubmit();
            this.ric9.form.onSubmit();
            this.ric10.form.onSubmit();
            ricorrentiValid=this.ric1.isValid() && this.ric2.isValid() && this.ric3.isValid() && this.ric4.isValid() && this.ric5.isValid() && this.ric6.isValid() && this.ric7.isValid() && this.ric8.isValid() && this.ric9.isValid() && this.ric10.isValid() && this.ric11.isValid()  && this.ric12.isValid() && this.ric13.isValid() && this.ric14.isValid() && this.ric15.isValid() && this.ric16.isValid() && this.ric17.isValid() ;



        break;
      }
 case '18': {

            this.ric1.form.onSubmit();
            this.ric2.form.onSubmit();
            this.ric3.form.onSubmit();
            this.ric4.form.onSubmit();
            this.ric5.form.onSubmit();
            this.ric6.form.onSubmit();
            this.ric7.form.onSubmit();
            this.ric8.form.onSubmit();
            this.ric9.form.onSubmit();
            this.ric10.form.onSubmit();
            ricorrentiValid=this.ric1.isValid() && this.ric2.isValid() && this.ric3.isValid() && this.ric4.isValid() && this.ric5.isValid() && this.ric6.isValid() && this.ric7.isValid() && this.ric8.isValid() && this.ric9.isValid() && this.ric10.isValid() && this.ric11.isValid()  && this.ric12.isValid() && this.ric13.isValid() && this.ric14.isValid() && this.ric15.isValid() && this.ric16.isValid() && this.ric17.isValid() && this.ric18.isValid();



        break;
      }
 case '19': {

            this.ric1.form.onSubmit();
            this.ric2.form.onSubmit();
            this.ric3.form.onSubmit();
            this.ric4.form.onSubmit();
            this.ric5.form.onSubmit();
            this.ric6.form.onSubmit();
            this.ric7.form.onSubmit();
            this.ric8.form.onSubmit();
            this.ric9.form.onSubmit();
            this.ric10.form.onSubmit();
            ricorrentiValid=this.ric1.isValid() && this.ric2.isValid() && this.ric3.isValid() && this.ric4.isValid() && this.ric5.isValid() && this.ric6.isValid() && this.ric7.isValid() && this.ric8.isValid() && this.ric9.isValid() && this.ric10.isValid() && this.ric11.isValid()  && this.ric12.isValid() && this.ric13.isValid() && this.ric14.isValid() && this.ric15.isValid() && this.ric16.isValid() && this.ric17.isValid() && this.ric18.isValid() && this.ric19.isValid();



        break;
      }
 case '20': {

            this.ric1.form.onSubmit();
            this.ric2.form.onSubmit();
            this.ric3.form.onSubmit();
            this.ric4.form.onSubmit();
            this.ric5.form.onSubmit();
            this.ric6.form.onSubmit();
            this.ric7.form.onSubmit();
            this.ric8.form.onSubmit();
            this.ric9.form.onSubmit();
            this.ric10.form.onSubmit();
            ricorrentiValid=this.ric1.isValid() && this.ric2.isValid() && this.ric3.isValid() && this.ric4.isValid() && this.ric5.isValid() && this.ric6.isValid() && this.ric7.isValid() && this.ric8.isValid() && this.ric9.isValid() && this.ric10.isValid() && this.ric11.isValid()  && this.ric12.isValid() && this.ric13.isValid() && this.ric14.isValid() && this.ric15.isValid() && this.ric16.isValid() && this.ric17.isValid() && this.ric18.isValid() && this.ric19.isValid() && this.ric20.isValid();



        break;
      }




      default: {
         console.log("lo switch non ha fatto match");

      }



    	}//chiuso switch


      if(ricorrentiValid){
        console.log("tutti i ricorrenti sono validi");
      }else
        console.log("esiste almeno un ricorrente non valido");

      this.ricForm.onSubmit();
     // this.detForm.onSubmit();
      //this.refBeneficiario.form.onSubmit();


      return (this.ricForm.valid  && ricorrentiValid );



    }//chiuso CreateParenti



  updateMessage(){
    this._flashMessagesService.show(this.statusMsg, { cssClass: 'alert-success', timeout: 3700 });
    console.warn("updateMessage()");
  }

    saveData(){

      if(!this.ricorsoDraft) {
          this.disableRefresh=true;



        if(this.createRicorso()){ //la create ricorso deve impostare i valori di DataRicorso
          //no, il binding deve avvenire nel form
          this.disableForm=true;
          this.hideSuccessMessage=false;
          this.statusService.updateParenti(this.dataRicorso);
          this.statusService.setPrintParenti(true);
          this.statusPrintRicorso=this.statusService.getParenti().print;
          this.statusMsg="campi impostati correttamente";
          this.updateMessage();

          console.log("campi impostati correttamente");

        }else
        console.log("campi non impostati correttamente");

      }

  }

updateDraft(event){
    console.log(`sto aggiornando lo stato bozza e ora vale ${event}`);
   this.ricorsoDraft=event;

    if(this.ricorsoDraft){
        this.hideSuccessMessage=true;
    } else{
       this.enableForm();
     }


    this.statusService.updateParenti(this.dataRicorso);
    this.save();
}


  updateData(){
      if(this.createRicorso()){
        this.disableForm=true;
        this.hideSuccessMessage=false;
        this.statusService.updateParenti(this.dataRicorso);
        this.statusService.setPrintParenti(true);
        this.statusPrintRicorso=this.statusService.getParenti().print;
        this.statusMsg="campi aggiornati correttamente";
        this.updateMessage();

      }
  }

  enableForm(){
       this.hideSuccessMessage=true;
       this.disableForm=false;
       this.statusMsg="documento pronto per essere modificato";

       this.updateMessage();

        this.statusService.setPrintParenti(false);
        this.statusService.setViewParenti(false);
        this.statusPrintRicorso=this.statusService.getParenti().print;
        this.statusViewRicorso=this.statusService.getParenti().view;



    }

   activateForm(){
       this.hideSuccessMessage=true;
       this.disableForm=false;

     }

    clear(){
        this.statusService.updateParenti(new DataParenti());
        this.statusService.setPrintParenti(false);
        this.statusService.setViewParenti(false);
        this.statusPrintRicorso=this.statusService.getParenti().print;
        this.statusViewRicorso=this.statusService.getParenti().view;
        this.dataRicorso=this.statusService.getParenti();
        this.activateForm();
        this.statusMsg="nuovo documento generato con successo";
        this.updateMessage();
    }


  showDocument(data){
  	console.log("showDocument() chiamata");
  	console.log("path : ",data.fileName);
    this.showWhere=data.fileName;
    this.statusService.setViewParenti(true);
    this.statusViewRicorso=this.statusService.getParenti().view;
     this.statusMsg="documento generato con successo";
    this.updateMessage();


  }

     showSuccessMsg(msg){
    console.log(msg);
     this.statusMsg="documento salvato con successo";
    this.updateMessage();

    this.transizioneModal.open();

    let sub=interval(1600).pipe(timeout(1500)).subscribe(value =>{

    }, error => {
      sub.unsubscribe();
      this.transizioneModal.close();
      // this.loadDoc(parseInt(this._idRicorso));
      this.statusService.setLoadedDoc(parseInt(this._idRicorso),'RICORSO');
       this.router.navigate(['/ricorso']);

    })

  }

  print(){
  	//1


    this.dbService.getWindow(this.statusService.getIdSportello())
    //.map(res =>res.json())
    .subscribe(data => this.secondStepPrint((data as Resp).data));
   }

   secondStepPrint(sportello){

     //2
     this._sportello=sportello[0];
     this._sportello.idSportello=+this._sportello.idSportello;
     console.log(`lo sportello vale `)
     console.log(this._sportello);

     this.obj=this.statusService.getParenti();




    console.log("print() called()");
    console.log(this.obj);



    //faccio chiamata a libreria per creare pdf

    var data = {"nRicorrenti":this.obj.details.nRicorrenti,"details":JSON.stringify(this.obj.details),"ric1":JSON.stringify(this.obj.ricorrenti[0]),
              "luogo":this._sportello.comune,
              "ric2":JSON.stringify(this.obj.ricorrenti[1]),
              "ric3":JSON.stringify(this.obj.ricorrenti[2]),
              "ric4":JSON.stringify(this.obj.ricorrenti[3]),
              "ric5":JSON.stringify(this.obj.ricorrenti[4]),
              "ric6":JSON.stringify(this.obj.ricorrenti[5]),
              "ric7":JSON.stringify(this.obj.ricorrenti[6]),
              "ric8":JSON.stringify(this.obj.ricorrenti[7]),
              "ric9":JSON.stringify(this.obj.ricorrenti[8]),
              "ric10":JSON.stringify(this.obj.ricorrenti[9]),
              "ric11":JSON.stringify(this.obj.ricorrenti[10]),
              "ric12":JSON.stringify(this.obj.ricorrenti[11]),
              "ric13":JSON.stringify(this.obj.ricorrenti[12]),
              "ric14":JSON.stringify(this.obj.ricorrenti[13]),
              "ric15":JSON.stringify(this.obj.ricorrenti[14]),
              "ric16":JSON.stringify(this.obj.ricorrenti[15]),
              "ric17":JSON.stringify(this.obj.ricorrenti[16]),
              "ric18":JSON.stringify(this.obj.ricorrenti[17]),
              "ric19":JSON.stringify(this.obj.ricorrenti[18]),
              "ric20":JSON.stringify(this.obj.ricorrenti[19]),
               "beneficiario":JSON.stringify(this.obj.beneficiario),

     "id":this.statusService.getId()};

    var json=JSON.stringify(data);



        console.log(json);



        var headers=new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
       return this.http.post(`${AppSettings.API_ENDPOINT}/php/documents/${AppSettings.TRIBUNAL_ENDPOINT}/parenti.php`,json,{headers:headers})
         //.map(res => res.json())
         .subscribe(data => this.showDocument(data));
   }



  save(){
    console.log("called save()");


    this.obj=this.statusService.getParenti();

  //console.log(this.obj);

  // var update= this.statusService.updateDoc('RICORSO');
    var data;
    var json;



  // if(update){
      let idDoc=this.statusService.getIdDocToSave();
      console.log("salvero su idRicorso: ",idDoc);


  data = {"details":JSON.stringify(this.obj.details),"rics":JSON.stringify(this.obj.ricorrenti),

              "beneficiario":JSON.stringify(this.obj.beneficiario),




     "idDoc":idDoc};


       json=JSON.stringify(data);
      console.log(json);
     this.dbService.update2dbParenti(json)
       //.map(res =>res.json())
       .subscribe(data => {

         this.statusService.setRelativesCompleted(true);

         this.showSuccessMsg(data)});


  }


  // da elenco-parenti a ricorso


    getIdTribunale():number{
        return this.statusService.getIdTribunale();
    }


    loadDoc(idDocumento:number){

    console.log("loadDoc chiamata");





       this.dbService.getRicorsoFromDb(idDocumento,this.getIdTribunale())
    //.map(res => res.json())
    .subscribe(data => this.configLoadedDoc((data as Resp).data,idDocumento));
      //console.log(data));
  }

    configLoadedDoc(content,idDocumento){

    console.log("config Loaded Doc chiamata");



      this.statusService.setLoadedDoc(idDocumento,'RICORSO');

    console.log(content);


          this.statusService.updateRicorso(content);
          this.statusService.setViewRicorso(false);
          this.router.navigate(['/ricorso/continua']);


  }







}
