import { Component, OnInit,ViewChild } from '@angular/core';
import {StatusService} from '../../../services/status.service';
import {DataBuyCar} from '../../../data/data-buy-car';
import {DbService} from '../../../services/db.service';
import { HttpHeaders,HttpClient} from '@angular/common/http';
import {AppSettings} from '../../../app-settings';
import { FlashMessagesService } from 'angular2-flash-messages';
import {Router} from '@angular/router';
import {Resp} from '../../../models/resp';
import {RespSavedDocument} from '../../../models/resp-saved-document';


@Component({
  selector: 'app-buy-car',
  templateUrl: './buy-car.component.html',
  styleUrls: ['./buy-car.component.css'],
  providers: [DbService]
})
export class BuyCarComponent implements OnInit {

  @ViewChild('buycarForm',{static:false}) form;

	private disableForm=false;
	private hideSuccessMessage=true;



  private dataBuyCar=new DataBuyCar();

  checkAccess(){
    if(!this.statusService.getAccess()){
        this.router.navigate(['']);
    }
  }

  ngOnInit() {

    this.checkAccess();
  	this.dataBuyCar=this.statusService.getBuyCar();

      let idB=this.statusService.getIdBeneficiario();
    if(idB==undefined){
      window.alert('seleziona un beneficiario prima di iniziare a produrre un documento');
      this.router.navigate(['/dashboard'])
    }
  }
  showUpdateButton(){
    return this.statusService.updateDoc('BUYCAR');
  }
    updateMessage(){
    this._flashMessagesService.show(this.statusMsg, { cssClass: 'alert-success', timeout: 3700 });
    console.warn("updateMessage()");
  }
  saveData(){
      this.statusService.setNewDoc('BUYCAR');
  	console.log(this.form.valid);
  	if(this.form.valid){
  		this.disableForm=true;
  		this.hideSuccessMessage=false;
  		this.statusService.updateBuyCar(this.dataBuyCar);
      this.statusService.setPrintBuyCar(true);
      this.statusPrintBuyCar=this.statusService.getBuyCar().print;
      this.statusMsg="campi impostati correttamente";
      this.updateMessage();


  	}




  }

  updateData(){
      if(this.form.valid){
        this.disableForm=true;
        this.hideSuccessMessage=false;
        this.statusService.updateBuyCar(this.dataBuyCar);
        this.statusService.setPrintBuyCar(true);
        this.statusPrintBuyCar=this.statusService.getBuyCar().print;
        this.statusMsg="campi aggiornati correttamente";
        this.updateMessage();



      }
  }
enableForm(){
       this.hideSuccessMessage=true;
       this.disableForm=false;
       this.statusMsg="documento pronto per essere modificato";

       this.updateMessage();



    }

   activateForm(){
       this.hideSuccessMessage=true;
       this.disableForm=false;

     }

    clear(){
        this.statusService.updateBuyCar(new DataBuyCar());
        this.statusService.setPrintBuyCar(false);
        this.statusService.setViewBuyCar(false);
        this.statusPrintBuyCar=this.statusService.getBuyCar().print;
        this.statusViewBuyCar=this.statusService.getBuyCar().view;
        this.dataBuyCar=this.statusService.getBuyCar();
        this.activateForm();
        this.statusMsg="nuovo documento generato con successo";
        this.updateMessage();
    }
    /* roba spostata da document-icon-sell-imm */

  public statusPrintBuyCar:boolean;
  public statusViewBuyCar:boolean;


  public statusMsg;

  private ads;
  private bene;
  private obj;
   public showWhere:string;

   constructor(private router:Router,private _flashMessagesService: FlashMessagesService,public statusService:StatusService,public dbService:DbService, public http:HttpClient) {


    this.statusPrintBuyCar=statusService.getBuyCar().print;
      console.log("statusPrint: ",this.statusPrintBuyCar);
      this.statusViewBuyCar=statusService.getBuyCar().view;
      console.log("statusView:",this.statusViewBuyCar);
  }




  setAds(a){

    //2
    this.ads=a;
    console.log(this.ads);

      this.dbService.getBeneficiario(this.statusService.getIdBeneficiario())
      //.map(res => res.json())
      .subscribe(data => this.setBene((data as Resp).data));
  }

  setBene(b){
    //3
    this.bene=b;
    console.log(this.bene);
    //faccio chiamata a libreria per creare pdf

    var data = {"ads":JSON.stringify(this.ads),"bene":JSON.stringify(this.bene),"obj":JSON.stringify(this.obj), "id":this.statusService.getId()};

    var json=JSON.stringify(data);



        console.log(json);
        var headers=new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
       return this.http.post(`${AppSettings.API_ENDPOINT}/php/documents/${AppSettings.TRIBUNAL_ENDPOINT}/buycar.php`,json,{headers:headers})
        // .map(res => res.json())
         .subscribe(data => this.showDocument(data));
  }

  showDocument(data){

    this.showWhere=data.fileName;
    this.statusService.setViewBuyCar(true);
    this.statusViewBuyCar=this.statusService.getBuyCar().view;
     this.statusMsg="documento generato con successo";
    this.updateMessage();



  }

  print(){

    //1


     this.obj=this.statusService.getBuyCar();
    this.dbService.getAmministratore(this.statusService.getId())
      //.map(res => res.json())
      .subscribe( data => this.setAds((data as Resp).data));




    console.log("print() called()");
    console.log(this.obj);


  }



  save(){
    console.log("ho chiamato il metodo save: ");
  this.obj=this.statusService.getBuyCar();

  //console.log(this.obj);

   var update= this.statusService.updateDoc('BUYCAR');
    var data;
    var json;



   if(update){
      let idDoc=this.statusService.getIdDocToSave();
      data = {"obj":JSON.stringify(this.obj), "id":this.statusService.getId(),"idDoc":idDoc,"kind":"BUYCAR","idBeneficiario":this.statusService.getIdBeneficiario()};
      json=JSON.stringify(data);
     this.dbService.update2db(json)
       //.map(res =>res.json())
       .subscribe(data => this.showSuccessMsg(data));

    }else
    {
      data = {"obj":JSON.stringify(this.obj), "id":this.statusService.getId(),"kind":"BUYCAR","idBeneficiario":this.statusService.getIdBeneficiario()};
      console.log("sono su ramo save (non update), salverò: ",data);
      json=JSON.stringify(data);
       this.dbService.save2db(json)
       //.map(res =>res.json())
       .subscribe(data =>{
           if((data as RespSavedDocument).id!=-1){
            this.statusService.updateIdDocToSave((data as RespSavedDocument).id);
          }

         this.showSuccessMsg(data)
       });


    }


  }

  showSuccessMsg(msg){
    console.log(msg);
     this.statusMsg="documento salvato con successo";
    this.updateMessage();

  }

}
