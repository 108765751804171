// input-beni-beneficiario.component.ts

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {UdineInitModelService} from '../../services/udine-init-model.service';

export interface BeneBeneficiario {
    id: number;
    nome: string;
    selezionato: boolean;
    valore: string;
}

@Component({
    selector: 'input-beni-beneficiario',
    templateUrl: './input-beni-beneficiario.component.html',
    styleUrls: ['./input-beni-beneficiario.component.scss']
})
export class InputBeniBeneficiarioComponent implements OnInit {
    @Input() beni: BeneBeneficiario[] = [];
    @Output() beniChange = new EventEmitter<BeneBeneficiario[]>();

    constructor(private udineInitModelService:UdineInitModelService) { }

    ngOnInit(): void {
        if (this.beni.length === 0) {
            this.beni = this.getDefaultBeni();
            this.beniChange.emit(this.beni);
        }
    }

    onCheckboxChange(bene: BeneBeneficiario): void {
        bene.selezionato = !bene.selezionato;
        if (!bene.selezionato) {
            bene.valore = '';
        }
        this.beniChange.emit(this.beni);
    }

    onInputChange(bene: BeneBeneficiario, nuovoValore: string): void {
        bene.valore = nuovoValore;
        this.beniChange.emit(this.beni);
    }

    private getDefaultBeni(): BeneBeneficiario[] {
        return this.udineInitModelService.getDefaultBeni();
    }
}
