import { Injectable } from '@angular/core';
import {ElementoLista, Opzione} from '../components/input-azioni-beneficiario/input-azioni-beneficiario.component';
import {AllegatoRicorso} from '../components/input-allegati-ricorso/input-allegati-ricorso.component';
import {BeneBeneficiario} from '../components/input-beni-beneficiario/input-beni-beneficiario.component';
import {BisognoMensile} from '../components/input-bisogni-mensili/input-bisogni-mensili.component';
import {OpzioneAlloggio} from '../components/input-situazione-alloggiativa/input-situazione-alloggiativa.component';

@Injectable({
  providedIn: 'root'
})
export class UdineInitModelService {

  constructor() { }



    public getDefaultElementi(): ElementoLista[] {
        return [
            { id: 1, descrizione: 'Dare il giusto significato al denaro', scelta: Opzione.Autonomo },
            { id: 2, descrizione: 'Dare il proprio consenso per le cure', scelta: Opzione.Autonomo },
            { id: 3, descrizione: 'Decidere in modo autonomo della sua vita affettiva e relazionale', scelta: Opzione.Autonomo },
            { id: 4, descrizione: 'Gestire rapporti in ambito lavorativo con superiori ed uffici aziendali', scelta: Opzione.Autonomo },
            { id: 5, descrizione: 'Ritirare personalmente la pensione', scelta: Opzione.Autonomo },
            { id: 6, descrizione: 'Fare acquisti personali entro un limite di € {{limite}} ({{input_choice}}) e effettuare operazioni bancarie / postali entro il limite di € {{limiteBancario}}', scelta: Opzione.Autonomo, inputFields: { limite: '', limiteBancario: '' }, inputChoice: 'settimanali' },
            { id: 7, descrizione: 'Compilare la dichiarazione dei redditi e gestire i rapporti con gli uffici amministrativi o uffici erogatori di pubblici servizi', scelta: Opzione.Autonomo },
            { id: 8, descrizione: 'Altro', scelta: Opzione.Autonomo, inputFields: { altro: '' } }
        ];
    }

    public getDefaultAllegati(): AllegatoRicorso[] {
        return [
            { id: 1, descrizione: "Estratto dell'atto di nascita e di residenza del Beneficiando", selezionato: false },
            { id: 2, descrizione: "Documentazione anagrafica che comprovi il rapporto di parentela tra il ricorrente e il Beneficiando (se il ricorrente è un parente)", selezionato: false },
            { id: 3, descrizione: "Fotocopia Carta d'Identità del ricorrente", selezionato: false },
            { id: 4, descrizione: "Certificato medico del Beneficiando (e/o certificazione della struttura ospitante) attestante le condizioni di vita personale del Beneficiando (sanitarie, sociali, psicologiche ed educative) ed attestante eventuale impossibilità dello stesso di raggiungere in udienza il tribunale ordinario ed attestante la capacità o meno del Beneficiando a rendere il consenso medico informato, nonché certificato del medico curante attestante la condizione psicofisica del soggetto con riferimento dettagliato alla sua incapacità parziale o totale di badare a se stesso", selezionato: false },
            { id: 5, descrizione: "Documentazione relativa alla situazione patrimoniale del Beneficiando (pensione di invalidità, pensioni di reversibilità, assegni di accompagnamento, stipendi, rendite provenienti da affitti, investimenti, interessi, conti correnti, titoli ecc.)", selezionato: false },
            { id: 6, descrizione: "Documentazione relativa alle proprietà del Beneficiando di beni immobili, quali le visure catastali.", selezionato: false },
            { id: 7, descrizione: "pagamento telematico dei diritti forfettari di notifica (art. 30 D.P.R. 115/02) pari a € 27,00", selezionato: false },
            { id: 8, descrizione: "NOTA DI ISCRIZIONE A RUOLO", selezionato: false }
        ];
    }

    public getDefaultBeni(): BeneBeneficiario[] {
        return [
            { id: 1, nome: 'Immobili', selezionato: false, valore: '' },
            { id: 2, nome: 'Veicoli', selezionato: false, valore: '' },
            { id: 3, nome: 'Depositi', selezionato: false, valore: '' },
            { id: 4, nome: 'Conti Correnti', selezionato: false, valore: '' },
            { id: 5, nome: 'Libretti di risparmio', selezionato: false, valore: '' },
            { id: 6, nome: 'Titoli', selezionato: false, valore: '' },
            { id: 7, nome: 'Assicurazioni', selezionato: false, valore: '' }
        ];
    }

    public getDefaultBisogni(): BisognoMensile[] {
        return [
            { id: 1, descrizione: 'Alimentazione, vestiario e cura della persona', importo: '' },
            { id: 2, descrizione: 'Casa (es, affitto, mutuo, spese condominiali)', importo: '' },
            { id: 3, descrizione: 'Cure sanitarie o riabilitative (es. occhiali, dentista, carrozzine, terapie specifiche)', importo: '' },
            { id: 4, descrizione: 'Frequenza di centri e/o comunità terapeutiche e relativi trasporti', importo: '' },
            { id: 5, descrizione: 'Tempo libero e vacanze', importo: '' },
            { id: 6, descrizione: 'Altro', importo: '',inputFields: { altro: '' }}
        ];
    }

    public getDefaultOpzioni(): OpzioneAlloggio[] {
        return [
            { id: 1, descrizione: 'Presso casa di cura, comunità o altra struttura', selezionato: false },
            { id: 2, descrizione: 'In famiglia con altre n. persone conviventi', selezionato: false, inputValue: '' },
            { id: 3, descrizione: 'Da solo', selezionato: false },
            { id: 4, descrizione: 'In abitazione con badante', selezionato: false },
            { id: 5, descrizione: 'Altro', selezionato: false, inputValue: '' }
        ];
    }


}
