// input-situazione-alloggiativa.component.ts

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {UdineInitModelService} from '../../services/udine-init-model.service';

export interface OpzioneAlloggio {
    id: number;
    descrizione: string;
    selezionato: boolean;
    inputValue?: string;
}

@Component({
    selector: 'input-situazione-alloggiativa',
    templateUrl: './input-situazione-alloggiativa.component.html',
    styleUrls: ['./input-situazione-alloggiativa.component.scss']
})
export class InputSituazioneAlloggiativaComponent implements OnInit {
    @Input() opzioni: OpzioneAlloggio[] = [];
    @Output() opzioniChange = new EventEmitter<OpzioneAlloggio[]>();

    constructor(private udineInitModelService:UdineInitModelService) { }

    ngOnInit(): void {
        if (this.opzioni.length === 0) {
            this.opzioni = this.getDefaultOpzioni();
            this.opzioniChange.emit(this.opzioni);
        }
    }

    onOpzioneChange(opzioneSelezionata: OpzioneAlloggio): void {
        this.opzioni.forEach(opzione => {
            opzione.selezionato = (opzione.id === opzioneSelezionata.id);
            if (!opzione.selezionato) {
                opzione.inputValue = '';
            }
        });
        this.opzioniChange.emit(this.opzioni);
    }

    onInputChange(opzione: OpzioneAlloggio, nuovoValore: string): void {
        opzione.inputValue = nuovoValore;
        this.opzioniChange.emit(this.opzioni);
    }

    private getDefaultOpzioni(): OpzioneAlloggio[] {
        return this.udineInitModelService.getDefaultOpzioni();
    }
}
