import { Injectable } from '@angular/core';
import {Account} from '../models/account';
import {AppSettings} from '../app-settings';
import {Amministratore} from '../models/amministratore';
import {DataSellImm} from '../data/data-sell-imm';
import {DataAuth} from '../data/data-auth';
import {DataBuyImm} from '../data/data-buy-imm';
import {DataSellGoods} from '../data/data-sell-goods';
import {DataBuyCar} from '../data/data-buy-car';
import {DataLegacy} from '../data/data-legacy';
import {DataExt} from '../data/data-ext';
import {DataRicorso} from '../data/data-ricorso';
import {DataParenti} from '../data/data-parenti';
import {DataSportello} from '../data/data-sportello';
import {actionsBeneficiando} from '../models/ricorso';
import {DbService} from './db.service';
import {of} from 'rxjs';

import { tap , switchMap} from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class StatusService {


  private token:string;

	private _account=new Account();
  private _specialAccount= new Account();
	private _amministratore=new Amministratore();

  private _sportello=new DataSportello();

  private _sellImm=new DataSellImm();
  private _auth=new DataAuth();
  private _buyImm= new DataBuyImm();
  private _sellGoods= new DataSellGoods();
  private _buyCar=new DataBuyCar();
  private _legacy= new DataLegacy();
  private _ricorso = new DataRicorso();
  private _parenti = new DataParenti();
  private _ext=new DataExt();
  private _idDocumento:number;
  private _loaded:boolean;
  private _kindLoaded:string;
  private _loadedAccount:boolean;
  private _access:boolean;
  private _surname:string;

  public selectedBeneficiario:boolean[];

  public setToken(token){
    this.token=token;
  }

  public getToken(){
    return this.token;
  }




  constructor(private dbService:DbService) {
    this._loaded=false;
    this._kindLoaded='';
   }

   setAccess(a){
     this._access=a;
   }

   getAccess(){
    return this.dbService.checkAccess().pipe(tap(data => console.log(data),switchMap(data =>  of(true))));
     //return this._access;
   }

   updateSportello(sportello:DataSportello){
     this._sportello.comune=sportello.comune;
     this._sportello.idSportello=sportello.idSportello;
     this._sportello.indirizzo=sportello.indirizzo;
     this._sportello.nomeSportello=sportello.nomeSportello;
     this._sportello.telefono=sportello.telefono;
     this._sportello.responsabile=sportello.responsabile;
     this._sportello.cf=sportello.cf;

   }

   setLoadedDoc(idDocumento:number,kind:string){
      console.warn("SetLoadedDoc: ",idDocumento);
     this._idDocumento=idDocumento;
     this._loaded=true;
     this._kindLoaded=kind;

     switch(kind){

       case 'SELLIMM':
           this.setPrintSellImm(false);
           this.setViewSellImm(false);


       break;
       case 'SELLGOODS':
               this.setPrintSellImm(false);
               this.setViewSellImm(false);
       break;

       case 'LEGACY':

               this.setPrintSellImm(false);
               this.setViewSellImm(false);
       break;

       case 'BUYCAR':
                 this.setPrintSellImm(false);
                 this.setViewSellImm(false);
       break;

       case 'BUYIMM':
               this.setPrintSellImm(false);
               this.setViewSellImm(false);
       break;


       case 'EXT':
               this.setPrintSellImm(false);
               this.setViewSellImm(false);
       break;

       case 'AUTH':
              this.setPrintSellImm(false);
              this.setViewSellImm(false);

       break;



     }
   }

   setNewDoc(kind){
     this._loaded=false;
     this._idDocumento=-1;

      switch(kind){

        case 'SELLIMM':
            this._sellImm=new DataSellImm();
          break;
        case 'BUYIMM':
          this._buyImm= new DataBuyImm();
          break;
        case 'SELLGOODS':
           this._sellGoods= new DataSellGoods();
          break;
        case 'AUTH':
           this._auth=new DataAuth();
          break;
        case 'LEGACY':
          this._legacy= new DataLegacy();
          break;
        case 'BUYCAR':
          this._buyCar=new DataBuyCar();
          break;
        case 'EXT':
           this._ext=new DataExt();
          break;

        case 'RICORSO':
          this._ricorso=new DataRicorso();

        break;



         case 'PARENTI':
          this._parenti=new DataParenti();
        break;
        case 'ALL':
           this._ext=new DataExt();
           this._sellImm=new DataSellImm();
           this._buyImm= new DataBuyImm();
           this._sellGoods= new DataSellGoods();
           this._auth=new DataAuth();
           this._legacy= new DataLegacy();
           this._buyCar=new DataBuyCar();
           this._ricorso=new DataRicorso();
          break;
      }









   }

   updateDoc(kind:string){


     return (this._loaded && (kind==this._kindLoaded)) || this._idDocumento!=-1;
   }

   getIdDocToSave(){
    console.warn("getIdDocToSave: ",this._idDocumento);
     return this._idDocumento;
   }


   updateIdDocToSave(id:number){
     this._idDocumento=id;

   }

     /**** ext ***/

    setPrintExt(status){
     this._ext.print=status;
   }

   setViewExt(status){
     this._ext.view=status;
   }


   updateExt(s){
     this._ext=s;
   }

   getExt(){
     return this._ext;
   }



   /*** ricorso ***/


   setGoToRelatives(status){
     this._ricorso.goToRelatives=status;
   }

   setRelativesCompleted(status){
     this._ricorso.relativesCompleted=status;
   }


   resetActions(){
        this._ricorso.details.azioni=[{description:"Il beneficiario è titolare di quale stipendio o pensioni?",value:''},
        {description:"Il beneficiario è titolare di conti o depositi in banca od alle Poste?",value:''},
        {description:"Il beneficiario è proprietario di quali immobili?",value:''},
        {description:"Il beneficiario sostiene una spesa mensile (utente, canoni, rette, badanti...) di circa euro...",value:''},
        {description:"A causa della sua situazione, la persona beneficiaria può compiere, da sola, i seguenti atti:",value:''},
        {description:"non può compiere, da sola, i seguenti atti",value:''}] as actionsBeneficiando[];
   }

    setPrintRicorso(status){
     this._ricorso.print=status;
   }

   setViewRicorso(status){
     this._ricorso.view=status;
     console.log("set View Ricorso completata");
   }


   updateRicorso(s){
     console.log("update ricorso chiamata");
     console.log(s);
     this._ricorso=s;
     console.log("fine update ricorso");
   }




   getRicorso(){
     return this._ricorso;
   }



   /*** parenti ***/



    setPrintParenti(status){
     this._parenti.print=status;
   }

   setViewParenti(status){
     this._parenti.view=status;
     console.log("set View Parenti completata");
   }


   updateParenti(s){
     console.log("update parenti chiamata");
     this._parenti=s;
     console.log("fine parenti ricorso");
   }


   resetParenti(){
       this._parenti=new DataParenti();
   }

   updateBeneficiarioInRicorso(b){
      console.log("updateBeneficiarioInRicorso() chiamata");
      this._parenti.beneficiario=b;

   }


      updateElencoParentiInRicorso(p){
      this._parenti.ricorrenti=p;
   }

   updateDetailsInRicorso(d){
      this._parenti.details=d;
   }


   getParenti(){
     return this._parenti;
   }



    /**** legacy ***/

    setPrintLegacy(status){
     this._legacy.print=status;
   }

   setViewLegacy(status){
     this._legacy.view=status;
   }


   updateLegacy(s){
     this._legacy=s;
   }

   getLegacy(){
     return this._legacy;
   }

    /**** buyCar ***/

    setPrintBuyCar(status){
     this._buyCar.print=status;
   }

   setViewBuyCar(status){
     this._buyCar.view=status;
   }


   updateBuyCar(s){
     this._buyCar=s;
   }

   getBuyCar(){
     return this._buyCar;
   }


   /**** sellGoods ***/

    setPrintSellGoods(status){
      console.log("la print di SellGoods vale: ",status);
     this._sellGoods.print=status;
   }

   setViewSellGoods(status){
     this._sellGoods.view=status;
   }


   updateSellGoods(s){
     this._sellGoods=s;
   }

   getSellGoods(){
     return this._sellGoods;
   }




   /**** buyImm ***/

    setPrintBuyImm(status){
     this._buyImm.print=status;
   }

   setViewBuyImm(status){
     this._buyImm.view=status;
   }


   updateBuyImm(s){
     this._buyImm=s;
   }

   getBuyImm(){
     return this._buyImm;
   }



   /**** auth ***/

    setPrintAuth(status){
     this._auth.print=status;
   }

   setViewAuth(status){
     this._auth.view=status;
   }


   updateAuth(s){
     this._auth=s;
   }

   getAuth(){
     return this._auth;
   }



   /***** sellImm ****/

   setPrintSellImm(status){
     this._sellImm.print=status;
   }

   setViewSellImm(status){
     this._sellImm.view=status;
   }


   updateSellImm(s){
     console.log(s);
     if(s!=null){
       this._sellImm=s;
     }else
       this._sellImm=new DataSellImm();
     console.log(this._sellImm);
   }

   getSellImm(){
     return this._sellImm;
   }

   setTribunal(account:Account){
       this._specialAccount.idTribunale = account.idTribunale;
       this._specialAccount.nome_tribunale = account.nome_tribunale;
       this.dbService.setTribunalName(account.nome_tribunale);
       AppSettings.TRIBUNAL_ENDPOINT = account.nome_tribunale;
   }

    getIdTribunale():number{
       return this._specialAccount.idTribunale;
    }

   getTribunalName(){
       return this._specialAccount.nome_tribunale;
   }

  updateSpecialAccount(account:Account){

    this._specialAccount=account;
    this._specialAccount.idAccount=+account.idAccount;
    this._specialAccount.completeAds=<boolean>this.parseBool(account.completeAds);
    this._specialAccount.completeBene=<boolean>this.parseBool(account.completeBene);
    this._specialAccount.kind=account.kind;
    if(this._specialAccount.kind=='opr'){
      this._specialAccount.idSportello=+account.idSportello;
      this._loadedAccount=false;
      this._specialAccount.supervisor=<boolean>this.parseBool(account.supervisor);
      console.warn("account  operatore dopo updateSpecialAccount", this._specialAccount);
    }

  }


  isSupervisor(){
    return this._specialAccount.supervisor;
  }

  setLoadedAccount(account:Account){
    console.log('statusService.setLoadedAccount:');
    console.log(account);
    this.updateAccount(account);
    this._loadedAccount=true;
  }


  downloadAccount(){
    this._loadedAccount=false;
    this.updateAccount(new Account());
  }
  isLoadedAccount(){
    return this._loadedAccount;
  }

  getLoadedAccount(){
    return this._loadedAccount;
  }

  updateAccount(account:Account){

  	this._account=account;
    this._account.idAccount=+account.idAccount;
    console.log(`ho settato l'account e idAccount vale ${this._account.idAccount}`);
    this._account.completeAds=<boolean>this.parseBool(account.completeAds);
    this._account.completeBene=<boolean>this.parseBool(account.completeBene);
    this._account.kind=account.kind;
    if(account.kind=='opr'){
      this._account.idSportello=account.idSportello;
    }

  	//return this;
  }

  isAdmin(){
    return (this._specialAccount.kind=='adm');
  }

   updateSurname(surname){
     this._surname=surname;
   }

  getSurname(){
    return this._surname;
  }

  getSportello(){
    return this._account.idSportello;
  }




  parseBool(b){
    if(b=="0")
      return false;
    else
      return true;
  }

  updateAmministratore(amm:Amministratore){
  	this._amministratore.updateAmmonistratore(amm);

    console.log("sono statusService.updateAmministratore, amm aggiornato, ora vale: ",this._amministratore);

    if(this.selectedBeneficiario==undefined){
      console.error('selectedBeneficiario undefined');
      this.selectedBeneficiario=new Array(this._amministratore.idBeneficiario.length).fill(false);
    }

    if(this.selectedBeneficiario.length!=this._amministratore.idBeneficiario.length){
      console.log(`lunghezze diverse ${this.selectedBeneficiario.length}!=${this._amministratore.idBeneficiario.length}`);

      this.selectedBeneficiario=new Array(this._amministratore.idBeneficiario.length).fill(false);

    }
  	this._amministratore.idAccount=this._account.idAccount;

    console.warn('dopo l\'update amministratoer il vettore selectedBeneficiario vale');
    console.log(this.selectedBeneficiario);
  }

  getAmministratore(){
  	return this._amministratore;
  }


  getId(){
  	return this._account.idAccount;
  }
  getSpecialId(){
    return this._specialAccount.idAccount;
  }


  getAccount(){
    return this._account;
  }

  getSpecialAccount(){
    return this._specialAccount;
  }

  getSportelloOpr(){
    return this.getIdSportello();
  }

  getIdSportello(){
    return this._specialAccount.idSportello;
  }


  getIdBeneficiario(){
    const idx=this.getActivatedBeneficiario();
    if(idx>=0)
   	  return this._amministratore.idBeneficiario[idx];
    else
      return undefined;
  }

   getIdBeneficiarioArray(){
     if(this._amministratore.idBeneficiario!=undefined){
        if(this._amministratore.idBeneficiario[0]==null)
         return [-1];
        else
         return this._amministratore.idBeneficiario;

     } else return [];

  }

  updateSelectedBeneficiario(j){
    for(let i=0;i<this.selectedBeneficiario.length; i++){
      if(i!=j)
         this.selectedBeneficiario[i]=false;
      if(i==j)
        this.selectedBeneficiario[i]=true;
    }
  }

  getSelectedBeneficiario(j){
    return this.selectedBeneficiario[j];
  }

  availableBeneficiario(){
    let av=false;
    if(this.selectedBeneficiario!=undefined)
      for(let i=0;i<this.selectedBeneficiario.length; i++){
        if(this.selectedBeneficiario[i])
          av=true;
      }
    return av;

  }

  activeBeneficiarioFromId(id){
    for(let i=0;i<this.selectedBeneficiario.length; i++){
        this.selectedBeneficiario[i]=false;

      }

    for(let k=0;k<this._amministratore.idBeneficiario.length;k++){
      if(Number(this._amministratore.idBeneficiario[k])==Number(id))
       this.selectedBeneficiario[k]=true;
    }

    console.log(`ho attivato id ${id} e ora il vettore vale`);
    console.log(this.selectedBeneficiario);
  }

  getActivatedBeneficiario(){
    console.log('questo è il vettore selectedBeneficiario');
    console.log(this.selectedBeneficiario);
    let k=-1;
    for(let i=0;i<this.selectedBeneficiario.length; i++){
      if(this.selectedBeneficiario[i])
        k=i;
      }
    console.log(`e l'indice di quello selezionato è ${k}`);
    return k;
  }




}
