// input-allegati-ricorso.component.ts

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {UdineInitModelService} from '../../services/udine-init-model.service';

export interface AllegatoRicorso {
    id: number;
    descrizione: string;
    selezionato: boolean;
}

@Component({
    selector: 'input-allegati-ricorso',
    templateUrl: './input-allegati-ricorso.component.html',
    styleUrls: ['./input-allegati-ricorso.component.scss']
})
export class InputAllegatiRicorsoComponent implements OnInit {
    @Input() allegati: AllegatoRicorso[] = [];
    @Output() allegatiChange = new EventEmitter<AllegatoRicorso[]>();

    constructor(private udineInitModelService:UdineInitModelService) { }

    ngOnInit(): void {
        if (this.allegati.length === 0) {
            this.allegati = this.getDefaultAllegati();
            this.allegatiChange.emit(this.allegati);
        }
    }

    onCheckboxChange(allegato: AllegatoRicorso): void {
        allegato.selezionato = !allegato.selezionato;
        this.allegatiChange.emit(this.allegati);
    }

    private getDefaultAllegati(): AllegatoRicorso[] {
        return this.udineInitModelService.getDefaultAllegati();
    }
}
