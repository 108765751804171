import { Component, OnInit,ViewChild } from '@angular/core';
import { RicorrenteComponent} from '../ricorrente/ricorrente.component';
import { LangService} from '../../services/lang.service';
import { Beneficiario } from '../../models/beneficiario';
import {Ricorso } from '../../models/ricorso';
import {DataRicorso} from '../../data/data-ricorso';
import {Ricorrente} from '../../models/ricorrente';
import { Subscription } from 'rxjs';

import {StatusService} from '../../services/status.service';
//import {DataSellImm} from '../../data-sell-imm';
import {DbService} from '../../services/db.service';
import { HttpHeaders,HttpClient} from '@angular/common/http';
import {AppSettings} from '../../app-settings';
import {Router} from '@angular/router';
import {NavigationEnd} from '@angular/router';

import {Resp} from '../../models/resp';

import { FlashMessagesService } from 'angular2-flash-messages';

import { SafeHtmlPipe } from '../../safe-html.pipe';
import {formatDate} from '@angular/common';
import {ElementoLista, Opzione} from '../input-azioni-beneficiario/input-azioni-beneficiario.component';
import {BisognoMensile} from '../input-bisogni-mensili/input-bisogni-mensili.component';
import {OpzioneAlloggio} from '../input-situazione-alloggiativa/input-situazione-alloggiativa.component';
import {BeneBeneficiario} from '../input-beni-beneficiario/input-beni-beneficiario.component';
import {AllegatoRicorso} from '../input-allegati-ricorso/input-allegati-ricorso.component';
import {UdineInitModelService} from '../../services/udine-init-model.service';


export class SavedRicorso {
  constructor(
    public status:string,
    public id:number,
    public msg:string,
    public data:string,
    public QUERY:string
    ){}

};

enum valueAction {
  AUTONOMO,
  ASSISTENZA,
  SOSTITUITO,
  OUT
};




@Component({
  selector: 'app-ricorso',
  templateUrl: './ricorso.component.html',
  styleUrls: ['./ricorso.component.css'],
  providers: [LangService,DbService]
})
export class RicorsoComponent implements OnInit {

   public statusPrintRicorso:boolean;
  public statusViewRicorso:boolean;

//verificare se sercono... ads, bene
  private ads;
  private bene;
  private obj;
  public showWhere:string;

   private disableRefresh:boolean;


   private ricorsoDraft;

   public actionsN:any;
   public checkAccordo:boolean;
   public checkRifiuta:boolean;
   public autoricorso:boolean;
   public routerSub:Subscription;
   public dataDocumento:string;

   // componente input-azioni-beneficiario per Udine

    public elementiAzioniLoaded: ElementoLista[] = [];
    public bisogniMensiliLoaded: BisognoMensile[] = [];
    public situazioneAlloggiativaLoaded: OpzioneAlloggio[] = [];
    public beniBeneficiarioLoaded: BeneBeneficiario[] = [];
    public allegatiRicorsoLoaded: AllegatoRicorso[] = [];


   constructor(private udineInitModelService:UdineInitModelService,private router:Router,private _flashMessagesService: FlashMessagesService,private langService:LangService, public statusService:StatusService,public dbService:DbService, public http:HttpClient) {


    this.statusPrintRicorso=statusService.getRicorso().print;
      console.log("statusPrint: ",this.statusPrintRicorso);
      this.statusViewRicorso=statusService.getRicorso().view;
      console.log("statusView:",this.statusViewRicorso);
      this.dataRicorso=new DataRicorso();

      this.dataRicorso=this.statusService.getRicorso();

         this.routerSub=this.router.events.subscribe(event =>{
           // console.log(event);
            if(event instanceof NavigationEnd){

                 console.warn("scheudle/n ROUTING EVENT!");

                 this.init();


               }
             });


     console.log("_____costruttore chiamato_____");

  }

  private disableForm=false;
  private hideSuccessMessage=true;

  public statusMsg;

 private dataRicorso;

 public goToRelatives:boolean;

 checkAccess(){
    this.statusService.getAccess().subscribe(data=>{
      console.log(`checAccess ha ricevuto ${data}`);
      if(!data)  this.router.navigate(['']);


    });



}

@ViewChild('accordo',{static:false}) accordo;
@ViewChild('rifiuta',{static:false}) rifiuta;

assertVolontaAccordo(){
  console.log(this.accordo);
  this.dataRicorso.details.volontaAccordo=this.accordo.nativeElement.checked;


  this.dataRicorso.details.volontaRifiuta=false;
  console.log(`ho impostato volonta accordo a true ${this.dataRicorso.details.volontaAccordo} e volontaRifiuta a false ${this.dataRicorso.details.volontaRufiuta} `);
  console.log("assertVolontaAccordo");
  this.checkAccordo=true;
  this.checkRifiuta=false;
}

assertVolontaRifiuta(){
  console.log(this.rifiuta);
  this.dataRicorso.details.volontaRifiuta=this.rifiuta.nativeElement.checked;
  this.dataRicorso.details.volontaAccordo=false;

  console.log(`ho impostato volonta accordo a false ${this.dataRicorso.details.volontaAccordo} e volontaRifiuta a true ${this.dataRicorso.details.volontaRufiuta} `);

  this.checkAccordo=false;
  this.checkRifiuta=true;
}

updateAutoricorso($event){
  console.log("update autoricorso!");
  console.log($event);

    this.autoricorso=$event.autoricorso;

    if($event.autoricorso){
      this.dataRicorso.beneficiario.nome=$event.ricorrente.nome;
      this.dataRicorso.beneficiario.cognome=$event.ricorrente.cognome;
      this.dataRicorso.beneficiario.codiceFiscale=$event.ricorrente.codiceFiscale;
      this.dataRicorso.beneficiario.luogoDiNascita=$event.ricorrente.luogoDiNascita;
      this.dataRicorso.beneficiario.dataDiNascita=$event.ricorrente.dataDiNascita;
      this.dataRicorso.beneficiario.sesso=$event.ricorrente.sesso;
      this.dataRicorso.beneficiario.codiceFiscale=$event.ricorrente.codiceFiscale;
      this.dataRicorso.beneficiario.indirizzo=$event.ricorrente.indirizzo;
    }

}




  ngOnInit() {

    this.init();



  }


    private mergeElementiAzioni(defaultArray: ElementoLista[], savedArray: any[]): ElementoLista[] {
        return defaultArray.map(defaultItem => {
            const savedItem = savedArray.find(item => item.id === defaultItem.id.toString());
            if (savedItem) {
                return {
                    ...defaultItem,
                    scelta: parseInt(savedItem.scelta,10) as Opzione,
                    inputFields: savedItem.inputFields || defaultItem.inputFields,
                    inputChoice: savedItem.inputChoice || defaultItem.inputChoice
                };
            }
            return defaultItem;
        });
    }

    private mergeSituazioneAlloggiativa(defaultArray: OpzioneAlloggio[], savedArray: any[]): OpzioneAlloggio[] {
        return defaultArray.map(defaultItem => {
            const savedItem = savedArray.find(item => item.id === defaultItem.id.toString());
            if (savedItem) {
                return {
                    ...defaultItem,
                    selezionato: savedItem.selezionato === "1",
                    inputValue: savedItem.inputValue || defaultItem.inputValue
                };
            }
            return defaultItem;
        });
    }

    private mergeBisogniMensili(defaultArray: BisognoMensile[], savedArray: any[]): BisognoMensile[] {
        return defaultArray.map(defaultItem => {
            const savedItem = savedArray.find(item => item.id === defaultItem.id.toString());
            if (savedItem) {
                return {
                    ...defaultItem,
                    importo: savedItem.importo,
                    inputFields: savedItem.inputFields || defaultItem.inputFields
                };
            }
            return defaultItem;
        });
    }

    private mergeBeniBeneficiario(defaultArray: BeneBeneficiario[], savedArray: any[]): BeneBeneficiario[] {
        return defaultArray.map(defaultItem => {
            const savedItem = savedArray.find(item => item.id === defaultItem.id.toString());
            if (savedItem) {
                return {
                    ...defaultItem,
                    selezionato: savedItem.selezionato === "1",
                    valore: savedItem.valore
                };
            }
            return defaultItem;
        });
    }

    private mergeAllegatiRicorso(defaultArray: AllegatoRicorso[], savedArray: any[]): AllegatoRicorso[] {
        return defaultArray.map(defaultItem => {
            const savedItem = savedArray.find(item => item.id === defaultItem.id.toString());
            if (savedItem) {
                return {
                    ...defaultItem,
                    selezionato: savedItem.selezionato === "1"
                };
            }
            return defaultItem;
        });
    }

  init(){

    this.autoricorso=false;
    this.goToRelatives=this.statusService.getRicorso().goToRelatives;
    this.ricorsoDraft=this.statusService.getRicorso().details.draft;
    console.log(`goToRelatives vale ${this.goToRelatives}`);


    console.log("/ricororso -> ng init chiamata");
    //this.nRicorrenti="1";
    this.checkAccess();
    this.dataRicorso=this.statusService.getRicorso();
    console.log('dataRicorso vale; ');
    console.log(this.dataRicorso);
    for(let ric of this.dataRicorso.ricorrenti){
       if(ric.candidato=='autoricorso'){
         this.autoricorso=true;
         console.log("fase di init, autoricorso messo a true");
       }
    }
    this.checkAccordo=this.dataRicorso.details.volontaAccordo;

    this.checkRifiuta=this.dataRicorso.details.volontaRifiuta;

    this.disableRefresh=false;

    this.statusPrintRicorso=this.statusService.getRicorso().print;
    this.statusViewRicorso=this.statusService.getRicorso().view;


      const idTribunale = this.getIdTribunale();

      if (idTribunale === 1) {  // Pordenone
          if (!this.dataRicorso.details.azioni || this.dataRicorso.details.azioni.length == 0) {
              console.log("azioni ha lunghezza zero");
              this.dataRicorso.details.azioni = [
                  {"description":"Il beneficiario è titolare di quale stipendio o pensioni?","value":''},
                  {"description":"Il beneficiario è titolare di conti o depositi in banca od alle Poste?","value":''},
                  {"description":"Il beneficiario è proprietario di quali immobili?","value":''},
                  {"description":"Il beneficiario sostiene una spesa mensile (utente, canoni, rette, badanti...) di circa euro...","value":''},
                  {"description":"A causa della sua situazione, la persona beneficiaria può compiere, da sola, i seguenti atti:","value":''},
                  {"description":"non può compiere, da sola, i seguenti atti","value":''}
              ];
          }
          this.actionsN = [...Array(this.dataRicorso.details.azioni.length).keys()];
          console.log(this.dataRicorso.details.azioni);
          console.log(this.actionsN);
      } else if (idTribunale === 2) {  // Udine
          // Inizializza con i valori predefiniti dal servizio
          this.elementiAzioniLoaded = this.udineInitModelService.getDefaultElementi();
          this.situazioneAlloggiativaLoaded = this.udineInitModelService.getDefaultOpzioni();
          this.bisogniMensiliLoaded = this.udineInitModelService.getDefaultBisogni();
          this.beniBeneficiarioLoaded = this.udineInitModelService.getDefaultBeni();
          this.allegatiRicorsoLoaded = this.udineInitModelService.getDefaultAllegati();

          // Se ci sono dati salvati in detailsUdine, aggiorniamo i valori predefiniti
          if (this.dataRicorso.detailsUdine) {

                  this.elementiAzioniLoaded = this.mergeElementiAzioni(this.elementiAzioniLoaded, this.dataRicorso.detailsUdine.elementiAzioni);
                  this.situazioneAlloggiativaLoaded = this.mergeSituazioneAlloggiativa(this.situazioneAlloggiativaLoaded, this.dataRicorso.detailsUdine.situazioneAlloggiativa);
                  this.bisogniMensiliLoaded = this.mergeBisogniMensili(this.bisogniMensiliLoaded, this.dataRicorso.detailsUdine.bisogniMensili);
                  this.beniBeneficiarioLoaded = this.mergeBeniBeneficiario(this.beniBeneficiarioLoaded, this.dataRicorso.detailsUdine.beniBeneficiario);
                  this.allegatiRicorsoLoaded = this.mergeAllegatiRicorso(this.allegatiRicorsoLoaded, this.dataRicorso.detailsUdine.allegatiRicorso);

          }
      }


  }

  ngOnDestroy(){
    this.routerSub.unsubscribe();

    console.log("componente distrutta");
  }




  ngAfterViewInit(){

      if(this.statusService.updateDoc('RICORSO')){
        console.log('ricorso in modalità update')
        let id=+this.statusService.getIdDocToSave();
          if(id!=-1){
            console.log('id diverso da -1')
            this.nav.navs.push( {'to':'/extraRicorso/'+id,'text':'extra','icon':'external-link-square'});
           } else {
             console.log('id vale -1')
           }
      } else {
        console.log('ricorso NON è in modalità update')
      }
  }



  ngAfterViewChecked(){
   // console.warn("after view checked");

  }


  ngAfterContentChecked(){
    //console.warn("ngAfterContentChecked");

    //if(!this.disableRefresh)
     //

   this.disableRefresh=false;

  }


    showUpdateButton(){
    return this.statusService.updateDoc('RICORSO');
  }

  updateMessage(){
    this._flashMessagesService.show(this.statusMsg, { cssClass: 'alert-success', timeout: 4800 });
    console.warn("updateMessage()");
  }

  //nRicorrenti:string;
  //beneficiario=new Beneficiario();
  //details = new Ricorso();
   disableCreateAccountForm=false;
   createAccountMessage="ricorso creato con successo!";
   hideCreateAccuontMessage=true;

    backToLogIn(){
      return this.langService.backToLogIn();
    }


    nuovoDocumento(){
      return this.langService.nuovoDocumento();
    }

    aggiornaDocumento(){
      return this.langService.aggiornaDocumento();
    }



    @ViewChild('nav',{static:false}) nav;
    //ric1;ric2;ric3;ric4;ric5;ric6;
    @ViewChild('ric1',{static:false}) ric1;
    @ViewChild('ric2',{static:false}) ric2;
    @ViewChild('ric3',{static:false}) ric3;
    @ViewChild('ric4',{static:false}) ric4;
    @ViewChild('ric5',{static:false}) ric5;
    @ViewChild('ric6',{static:false}) ric6;

    @ViewChild('ricorsoForm',{static:false}) ricForm;
    @ViewChild('detailsForm',{static:false}) detForm;
    @ViewChild('anagrafica',{static:false}) refBeneficiario;


    createRicorso(){
      console.log("bottone cliccato");
      console.log("riccorrenti: ",this.dataRicorso.details.nRicorrenti);

      let ricorrentiValid=false;

      switch(this.dataRicorso.details.nRicorrenti.toString()){


          case '0':{
              console.log("nessun ricorrente");
              ricorrentiValid=true;

          break;
        }

        case '1':{
          console.log("un ricorrente");
          console.log(this.ric1.ricorrente);
          this.ric1.form.onSubmit();
          ricorrentiValid=this.ric1.isValid();
        break;
      }
        case '2':{
           console.log("due ricorrenti");
           console.log(this.ric1.ricorrente);
           console.log(this.ric2.ricorrente);
            this.ric1.form.onSubmit();
            this.ric2.form.onSubmit();
            ricorrentiValid=this.ric1.isValid() && this.ric2.isValid();
        break;
      }
        case '3':{
            this.ric1.form.onSubmit();
            this.ric2.form.onSubmit();
            this.ric3.form.onSubmit();
            ricorrentiValid=this.ric1.isValid() && this.ric2.isValid() && this.ric3.isValid();

        break;
      }
        case '4': {
            this.ric1.form.onSubmit();
            this.ric2.form.onSubmit();
            this.ric3.form.onSubmit();
            this.ric4.form.onSubmit();
            ricorrentiValid=this.ric1.isValid() && this.ric2.isValid() && this.ric3.isValid() && this.ric4.isValid();

        break;
      }
        case '5': {
            this.ric1.form.onSubmit();
            this.ric2.form.onSubmit();
            this.ric3.form.onSubmit();
            this.ric4.form.onSubmit();
            this.ric5.form.onSubmit();
            ricorrentiValid=this.ric1.isValid() && this.ric2.isValid() && this.ric3.isValid() && this.ric4.isValid() && this.ric5.isValid();


        break;
      }
        case '6': {

            this.ric1.form.onSubmit();
            this.ric2.form.onSubmit();
            this.ric3.form.onSubmit();
            this.ric4.form.onSubmit();
            this.ric5.form.onSubmit();
            this.ric6.form.onSubmit();
            ricorrentiValid=this.ric1.isValid() && this.ric2.isValid() && this.ric3.isValid() && this.ric4.isValid() && this.ric5.isValid() && this.ric6.isValid();



        break;
      }

      default: {
         console.log("lo switch non ha fatto match");

      }



      }//chiuso switch


      if(ricorrentiValid){
        console.log("tutti i ricorrenti sono validi");
      }else
        console.log("esiste almeno un ricorrente non valido");

      this.ricForm.onSubmit();
      this.detForm.onSubmit();
      if(!this.autoricorso)
        this.refBeneficiario.form.onSubmit();

      if(!this.autoricorso)
        return (this.ricForm.valid && this.detForm.valid && ricorrentiValid && this.refBeneficiario.form.valid);
      else
         return (this.ricForm.valid && this.detForm.valid && ricorrentiValid);



    }//chiuso CreateRicorso









  setElencoParenti(data){


    console.warn("setElencoParenti()");
    console.log("parenti caricati da db:");
    console.log(data);
    if(data!=null)
      if(data.parenti!=false){
      console.log("ho caricato i parenti dal db");
      this.statusService.updateElencoParentiInRicorso(data.parenti);

      let _currentPar=this.statusService.getParenti();
      console.log('componente ricorso, attualmente elenco parenti vale:')
      console.log(_currentPar.ricorrenti);

     /* if(_currentPar.ricorrenti.length<=10){
        while(_currentPar.ricorrenti.length<20){
          _currentPar.ricorrenti=[..._currentPar.ricorrenti,new Ricorrente()];
        }

        console.log('elenco parenti reinizializzato a 20 slot')
        this.statusService.updateElencoParentiInRicorso(_currentPar);
        console.log(_currentPar.ricorrenti);
      } */

      this.statusService.updateDetailsInRicorso(data.details);

      this.statusService.setRelativesCompleted(true);
      console.warn('elenco parenti caricato dal db')
    }else {
      //parenti non cariati
        //elenco parenti vuoto
        this.statusService.setRelativesCompleted(true);
        console.error("errore nel caricmento dell'elenco parenti, elenco parenti vuoto magari");
    }

   if(this.statusService.getRicorso().relativesCompleted){
         this.statusService.setPrintRicorso(true);
         this.statusPrintRicorso=this.statusService.getRicorso().print;
      }


     // this.dataRicorso=this.statusService.getParenti();

  }


  saveData(){
    if(!this.ricorsoDraft){

        this.disableRefresh=true;

          this.statusService.setNewDoc('RICORSO');

           this.statusService.setPrintRicorso(false);
            this.statusService.setViewRicorso(false);
              this.statusPrintRicorso=this.statusService.getRicorso().print;
            this.statusViewRicorso=this.statusService.getRicorso().view;

        if(this.createRicorso()){ //la create ricorso deve impostare i valori di DataRicorso
          //no, il binding deve avvenire nel form
          this.disableForm=true;
          this.hideSuccessMessage=false;
          this.dataRicorso.details.draft=this.ricorsoDraft;
          this.statusService.updateRicorso(this.dataRicorso);


          //carico i dati dell'elenco parenti






          this.statusMsg="campi impostati correttamente";
          this.updateMessage();




          console.log("campi impostati correttamente");

        }else
        console.log("campi non impostati correttamente");
    }
  }


  getGoToRelavies(){
    return this.goToRelatives;
  }


  updateDraft(event){
    console.warn(`update draft event ${event}`);
    this.ricorsoDraft=event;

    if(this.ricorsoDraft){
        this.hideSuccessMessage=true;
    } else{
       this.enableForm()

    }

    this.saveDraft();
  }

  saveDraft(){
    console.warn(`ricorso draft: ${this.ricorsoDraft}`);
     if(this.ricorsoDraft){

      let id=+this.statusService.getIdDocToSave();
      if(id!=-1 && id !=undefined){

      } else {
          this.statusService.setNewDoc('RICORSO');

          this.statusService.setPrintRicorso(false);
          this.statusService.setViewRicorso(false);
          this.statusPrintRicorso=this.statusService.getRicorso().print;
          this.statusViewRicorso=this.statusService.getRicorso().view;

      }

      this.dataRicorso.details.draft=this.ricorsoDraft;
      this.statusService.updateRicorso(this.dataRicorso);
      this.save();
    }
  }



  updateData(){
     if(!this.ricorsoDraft){

          this.statusService.setPrintRicorso(false);
          this.statusService.setViewRicorso(false);
            this.statusPrintRicorso=this.statusService.getRicorso().print;
          this.statusViewRicorso=this.statusService.getRicorso().view;

        if(this.createRicorso()){
          this.disableForm=true;
          this.hideSuccessMessage=false;
          this.dataRicorso.details.draft=this.ricorsoDraft;
          this.statusService.updateRicorso(this.dataRicorso);

          let id=+this.statusService.getIdDocToSave();
          if(id!=-1){
            console.warn(`id del ricorso corrente vale ${id}`);
              this.dbService.getParentiFromDb(+this.statusService.getIdDocToSave()).subscribe(data => this.setElencoParenti((data as Resp).data));

            this.statusService.setGoToRelatives(true);
            this.goToRelatives=this.statusService.getRicorso().goToRelatives;

          }



          this.statusMsg="campi aggiornati correttamente";
          this.updateMessage();


        console.error(`goToRelatives vale ${this.goToRelatives}`);


        }
    }
  }

  enableForm(){
       this.hideSuccessMessage=true;
       this.disableForm=false;
       this.statusMsg="documento pronto per essere modificato";

       this.updateMessage();

        this.statusService.setPrintRicorso(false);
        this.statusService.setViewRicorso(false);
        this.statusPrintRicorso=this.statusService.getRicorso().print;
        this.statusViewRicorso=this.statusService.getRicorso().view;



    }

   activateForm(){
       this.hideSuccessMessage=true;
       this.disableForm=false;

     }

    clear(){
        this.statusService.updateRicorso(new DataRicorso());
        this.statusService.setPrintRicorso(false);
        this.statusService.setViewRicorso(false);
        this.statusPrintRicorso=this.statusService.getRicorso().print;
        this.statusViewRicorso=this.statusService.getRicorso().view;
        this.dataRicorso=this.statusService.getRicorso();
        this.activateForm();
        this.statusMsg="nuovo documento generato con successo";
        this.updateMessage();
    }






   secondStepPrint(sportello){
        console.log(`secondStepPrint sportello vale`);
        console.log(sportello);

        //1
        this.obj=this.statusService.getRicorso();
        const _par=this.statusService.getParenti();
        const idTribunale = this.getIdTribunale(); // Assumiamo che esista un metodo per ottenere idTribunale


        console.log('ricorrente 0 vale');
       console.log(this.dataRicorso.ricorrenti[0]);

        console.log("print() called(),oggetto ricorso");
        console.log(this.obj);
        console.log("oggetto parenti");
        console.log(_par);



        //faccio chiamata a libreria per creare pdf
        let _volonta='';
        if(this.obj.details.volontaAccordo)
          _volonta="Il beneficiando è d'accordo alla misura di protezione.";
        if(this.obj.details.volontaRifiuta)
          _volonta="Il beneficiando rifiuta la misura di protezione.";

    const baseData = {
           nRicorrenti: this.obj.details.nRicorrenti,
           details: JSON.stringify(this.obj.details),
           ric1: JSON.stringify(this.dataRicorso.ricorrenti[0]),
           ric2: JSON.stringify(this.dataRicorso.ricorrenti[1]),
           ric3: JSON.stringify(this.dataRicorso.ricorrenti[2]),
           ric4: JSON.stringify(this.dataRicorso.ricorrenti[3]),
           ric5: JSON.stringify(this.dataRicorso.ricorrenti[4]),
           ric6: JSON.stringify(this.dataRicorso.ricorrenti[5]),
           beneficiario: JSON.stringify(this.dataRicorso.beneficiario),
           id: this.statusService.getId(),
           actions: JSON.stringify(this.obj.details.azioni),
           needs: JSON.stringify(this.obj.details.bisogni),
           durata: this.obj.details.durata,
           durataValue: this.obj.details.durataValue,
           volonta: _volonta,
           nParenti: _par.details.nRicorrenti,
           detailsParenti: JSON.stringify(_par.details),
           ric1Parenti: JSON.stringify(_par.ricorrenti[0]),
           luogo: sportello.comune,
           ric2Parenti: JSON.stringify(_par.ricorrenti[1]),
           ric3Parenti: JSON.stringify(_par.ricorrenti[2]),
           ric4Parenti: JSON.stringify(_par.ricorrenti[3]),
           ric5Parenti: JSON.stringify(_par.ricorrenti[4]),
           ric6Parenti: JSON.stringify(_par.ricorrenti[5]),
           ric7Parenti: JSON.stringify(_par.ricorrenti[6]),
           ric8Parenti: JSON.stringify(_par.ricorrenti[7]),
           ric9Parenti: JSON.stringify(_par.ricorrenti[8]),
           ric10Parenti: JSON.stringify(_par.ricorrenti[9]),
           ric11Parenti: JSON.stringify(_par.ricorrenti[10]),
           ric12Parenti: JSON.stringify(_par.ricorrenti[11]),
           ric13Parenti: JSON.stringify(_par.ricorrenti[12]),
           ric14Parenti: JSON.stringify(_par.ricorrenti[13]),
           ric15Parenti: JSON.stringify(_par.ricorrenti[14]),
           ric16Parenti: JSON.stringify(_par.ricorrenti[15]),
           ric17Parenti: JSON.stringify(_par.ricorrenti[16]),
           ric18Parenti: JSON.stringify(_par.ricorrenti[17]),
           ric19Parenti: JSON.stringify(_par.ricorrenti[18]),
           ric20Parenti: JSON.stringify(_par.ricorrenti[19]),
           data: JSON.stringify(this.obj.data),
           idTribunale: idTribunale
       };

       let data = baseData;

       if (idTribunale === 2) {
           // Aggiungiamo i dati specifici per Udine
           const udineData = {
               elementiAzioni: JSON.stringify(this.elementiAzioniLoaded),
               bisogniMensili: JSON.stringify(this.bisogniMensiliLoaded),
               situazioneAlloggiativa: JSON.stringify(this.situazioneAlloggiativaLoaded),
               beniBeneficiario: JSON.stringify(this.beniBeneficiarioLoaded),
               allegatiRicorso: JSON.stringify(this.allegatiRicorsoLoaded)
           };

           data = { ...baseData, ...udineData };
       }

        const json=JSON.stringify(data);
        console.log(json);
        const headers=new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
        return this.http.post(`${AppSettings.API_ENDPOINT}/php/documents/${AppSettings.TRIBUNAL_ENDPOINT}/ricorso.php`,json,{headers:headers})
         // .map(res => res.json())
         .subscribe(dataPost => this.showDocument(dataPost));


   }








  print(){



  this.dbService.getWindow(this.statusService.getIdSportello())
    //.map(res =>res.json())
    .subscribe(data => this.secondStepPrint((data as Resp).data[0]));
   }

    //1







  showDocument(data){

    this.showWhere=data.fileName;

    this.statusService.setViewRicorso(true);
    this.statusViewRicorso=this.statusService.getRicorso().view;
     this.statusMsg="documento generato con successo";
    this.updateMessage();


  }


  showApiMsg(data){
    this.statusMsg=data.msg;
    this.updateMessage();
  }


   showSuccessMsg(msg){
    console.log(msg);
     this.statusMsg="documento salvato con successo";
    this.updateMessage();



  }


    save() {
        console.log("called save()");

        this.obj = this.statusService.getRicorso();
        let idOperatore = this.statusService.getSpecialId();

        var update = this.statusService.updateDoc('RICORSO');
        var data;
        var json;

        // Prepare the new elements
        const newElements = {
            elementiAzioni: this.elementiAzioniLoaded,
            bisogniMensili: this.bisogniMensiliLoaded,
            situazioneAlloggiativa: this.situazioneAlloggiativaLoaded,
            beniBeneficiario: this.beniBeneficiarioLoaded,
            allegatiRicorso: this.allegatiRicorsoLoaded
        };

        // Get idTribunale
        const idTribunale = this.getIdTribunale();

        if (update) {
            let idDoc = this.statusService.getIdDocToSave();

            if (this.statusViewRicorso) {
                this.dbService.addZip(idDoc, this.showWhere).subscribe((data) => {
                    console.log('_____addZip ricorso');
                    console.log(data);
                    console.log('____');

                    setTimeout(() => {
                        this.showApiMsg(data);
                    }, 1500);
                });
            }

            data = {
                details: JSON.stringify(this.obj.details),
                rics: JSON.stringify(this.obj.ricorrenti),
                beneficiario: JSON.stringify(this.obj.beneficiario),
                idDoc: idDoc,
                idSportello: this.statusService.getSportelloOpr(),
                modificationAuthor: idOperatore,
                data: JSON.stringify(this.obj.data),
                idTribunale: idTribunale,
                detailsUdine: idTribunale === 2 ? JSON.stringify(newElements) : null
            };

            json = JSON.stringify(data);
            this.dbService.update2dbRicorso(json)
                .subscribe(data => this.showSuccessMsg(data));

        } else {
            data = {
                details: JSON.stringify(this.obj.details),
                rics: JSON.stringify(this.obj.ricorrenti),
                beneficiario: JSON.stringify(this.obj.beneficiario),
                idSportello: this.statusService.getSportelloOpr(),
                kind: "RICORSO",
                creationAuthor: idOperatore,
                data: JSON.stringify(this.obj.data),
                idTribunale: idTribunale,
                detailsUdine: idTribunale === 2 ? JSON.stringify(newElements) : null
            };

            json = JSON.stringify(data);

            console.log("sono su ramo save (non update), salverò: ", json);
            this.dbService.save2dbRicorso(json)
                .subscribe(data => {
                    console.warn(`id del documento appena salvato vale ${(data as SavedRicorso).id}`);
                    if ((data as SavedRicorso).id != -1) {
                        this.statusService.updateIdDocToSave((data as SavedRicorso).id);
                        if (this.statusViewRicorso) {
                            this.dbService.addZip((data as SavedRicorso).id, this.showWhere).subscribe((data) => {
                                console.log('_____');
                                console.log(data);
                                console.log('____');
                                setTimeout(() => {
                                    this.showApiMsg(data);
                                }, 1500);
                            });
                        }

                        this.statusService.setGoToRelatives(true);
                        this.goToRelatives = this.statusService.getRicorso().goToRelatives;
                    }

                    this.showSuccessMsg(data);
                });
        }
    }


relatives(){
      this.router.navigate(['/elencoParenti/'+ this.statusService.getIdDocToSave()]);
}




goToRelativesDraft($event){

   if(this.ricorsoDraft){





          let id=+this.statusService.getIdDocToSave();
          if(id!=-1){
            console.warn(`id del ricorso corrente vale ${id}`);
              this.dbService.getParentiFromDb(+this.statusService.getIdDocToSave()).subscribe(data => this.setElencoParenti((data as Resp).data));

            this.statusService.setGoToRelatives(true);
            this.goToRelatives=this.statusService.getRicorso().goToRelatives;
            this.relatives();

          }






        console.error(`goToRelatives vale ${this.goToRelatives}`);



    }


}


    getIdTribunale():number{
        return this.statusService.getIdTribunale();
    }







}
